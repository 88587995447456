import { HttpClient, HttpParams } from '@angular/common/http';
import { Organization } from './organization.model';
import { Store } from './store.model';
import { Injectable } from '@angular/core';
import { RouteService } from '../../../route.service';
import { ToastService } from '../../../toast.service';
import { environment } from '../../../../environments/environment';
import { Currency } from './Currency.model';
import { catchError, map } from 'rxjs/operators';
import { lastValueFrom, throwError } from 'rxjs';
import { Gateway } from '../../gateway/Gateway.model';

@Injectable()
export class MerchantOrganizationService {
  selectedOrganization = -1;
  uploadedPercentage = 0;
  branchesWithOrganizations: number[];

  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  /*
    If the user is a super admin then all of the organizations is loaded
    Else the api call is made to get the organizations associated with the logged in user then
    for each of these organization the data of the organization is obtained
    */
  getOrganizations(changeView: boolean) {
    if (+localStorage.getItem('spareAdminRole') === 1 && !changeView) {
      return new Promise<Organization[]>((resolve, reject) => {
        const url: string = this.baseUrl + '/Organizations/Admin/All';
        const organizations: Organization[] = [];
        this.httpClient.get<any>(url).subscribe(
          (organizationsData: any) => {
            for (const organization of organizationsData) {
              const currency: Currency = {
                currencyID: organization.currencyID,
                currencyCode: organization.currencyCode,
                longName: organization.currencyLongName,
                precision: organization.currencyPrecision,
              };

              const gateway: Gateway = {
                gatewayName: organization.gatewayName,
                internalGatewayName: organization.internalGatewayName,
                gatewayInternalCode: organization.gatewayInternalCode,
                gatewayID: organization.gatewayID,
              };

              const tempOrganization: Organization = new Organization(
                organization.organizationID,
                organization.organizationName,
                organization.organizationShortName,
                organization.lowBalanceAmount,
                organization.logoURL,
                organization.colorCode,
                organization.requiresVerification,
                organization.handlesBilling,
                organization.transactionPINDisabled,
                organization.accessNotificationsEnabled,
                organization.paymentRequestNotificationsEnabled,
                organization.busNotificationsEnabled,
                organization.canteenMenusAvailableInApp,
                organization.acceptsCash,
                currency,
                gateway,
                organization.timeZoneID,
                organization.timezoneDatabaseName,
                organization.legacyPairingFlow,
                organization.hidden,
                organization.enableLimits,
                organization.enableAllergens,
                organization.enableFees,
                organization.enableOnlinePickup,
                organization.enableProductRestrictions,
                organization.promptToLinkAfterClaiming,
                organization.postClaimingURL,
                organization.selfRegistration,
                organization.autoGenerateStudentID,
                organization.balanceDescription,
                organization.allowSchedulingOnAnotherBus,
                organization.autoPairOnParentPhoneNumberMatch,
                organization.newOnlineStoreEnabled,
                organization.pickupCutoffTime,
                organization.pickupNotePlaceholder,
                organization.busExcusesEnabled
              );
              organizations.push(tempOrganization);
            }
            resolve(organizations);
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
      });
    } else {
      return new Promise<Organization[]>((resolve, reject) => {
        const url: string = this.baseUrl + '/AdminUsers/Admin/OrganizationsByAdmin';
        const organizations: Organization[] = [];
        this.httpClient.get<any>(url).subscribe(
          (organizationsData: any) => {
            for (const organization of organizationsData) {
              const currency: Currency = {
                currencyID: organization.currencyID,
                currencyCode: organization.currencyCode,
                longName: organization.currencyLongName,
                precision: organization.currencyPrecision,
              };

              const gateway: Gateway = {
                gatewayName: organization.gatewayName,
                internalGatewayName: organization.internalGatewayName,
                gatewayInternalCode: organization.gatewayInternalCode,
                gatewayID: organization.gatewayID,
              };

              const tempOrganization: Organization = new Organization(
                organization.organizationID,
                organization.organizationName,
                organization.organizationShortName,
                organization.lowBalanceAmount,
                organization.logoURL,
                organization.colorCode,
                true,
                organization.handlesBilling,
                organization.transactionPINDisabled,
                organization.accessNotificationsEnabled,
                organization.paymentRequestNotificationsEnabled,
                organization.busNotificationsEnabled,
                organization.canteenMenusAvailableInApp,
                organization.acceptsCash,
                currency,
                gateway,
                organization.timeZoneID,
                organization.timezoneDatabaseName,
                organization.legacyPairingFlow,
                organization.hidden,
                organization.enableLimits,
                organization.enableAllergens,
                organization.enableFees,
                organization.enableOnlinePickup,
                organization.enableProductRestrictions,
                organization.promptToLinkAfterClaiming,
                organization.postClaimingURL,
                organization.selfRegistration,
                organization.autoGenerateStudentID,
                organization.balanceDescription,
                organization.allowSchedulingOnAnotherBus,
                organization.autoPairOnParentPhoneNumberMatch,
                organization.newOnlineStoreEnabled,
                organization.pickupCutoffTime,
                organization.pickupNotePlaceholder,
                organization.busExcusesEnabled
              );
              organizations.push(tempOrganization);
            }
            resolve(organizations);
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
      });
    }
  }

  /*
    Gets in all the branches that are linked to the input organization
    */
  getStore(organizationID: number): Promise<Store[]> {
    const url: string = this.baseUrl + '/Organizations/Branches/Admin/OrganizationID';
    const stores: Store[] = [];
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(url, {
          params: new HttpParams().set('organizationID', '' + organizationID),
        })
        .subscribe(
          (storesData: any) => {
            for (const store of storesData) {
              const tempStore: Store = new Store(
                store.organizationBranchEntryID,
                store.organizationID,
                store.branchID,
                store.area,
                store.branchName,
                store.address,
                store.merchantName,
                store.merchantColorLogoURL,
                store.merchantColorCode,
                store.merchantID
              );
              stores.push(tempStore);
            }
            resolve(stores);
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /*
    Calls on the api to remove the link made from an organization to a branch
    */
  removeStore(organizationID: number, branchID) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Organizations/Branches/Admin/Delete';
      this.httpClient
        .post(url, {
          organizationID: organizationID,
          branchID: branchID,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Branch removed successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /*
    Gets the details of the selected organization
    */
  getOrganizationByID(organizationID = null): Promise<Organization> {
    let res: Organization;
    if (!organizationID) organizationID = this.selectedOrganization;
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Organizations/Admin/GetByID';
      this.httpClient
        .get<any>(url, {
          params: new HttpParams().set('organizationID', '' + organizationID),
        })
        .subscribe(
          (organization: any) => {
            console.log('org', organization);

            const currency: Currency = {
              currencyID: organization[0].currencyID,
              currencyCode: organization[0].currencyCode,
              longName: organization[0].currencyLongName,
              precision: organization[0].currencyPrecision,
            };

            const gateway: Gateway = {
              gatewayName: organization[0].gatewayName,
              internalGatewayName: organization[0].internalGatewayName,
              gatewayInternalCode: organization[0].gatewayInternalCode,
              gatewayID: organization[0].gatewayID,
            };

            res = new Organization(
              organization[0].organizationID,
              organization[0].organizationName,
              organization[0].organizationShortName,
              organization[0].lowBalanceAmount,
              organization[0].logoURL,
              organization[0].colorCode,
              organization[0].requiresVerification,
              organization[0].handlesBilling,
              organization[0].transactionPINDisabled,
              organization[0].accessNotificationsEnabled,
              organization[0].paymentRequestNotificationsEnabled,
              organization[0].busNotificationsEnabled,
              organization[0].canteenMenusAvailableInApp,
              organization[0].acceptsCash,
              currency,
              gateway,
              organization[0].timeZoneID,
              organization[0].timeZoneDatabaseName,
              organization[0].legacyPairingFlow,
              organization[0].hidden,
              organization[0].enableLimits,
              organization[0].enableAllergens,
              organization[0].enableFees,
              organization[0].enableOnlinePickup,
              organization[0].enableProductRestrictions,
              organization[0].promptToLinkAfterClaiming,
              organization[0].postClaimingURL,
              organization[0].selfRegistration,
              organization[0].autoGenerateStudentID,
              organization[0].balanceDescription,
              organization[0].allowSchedulingOnAnotherBus,
              organization[0].autoPairOnParentPhoneNumberMatch,
              organization[0].newOnlineStoreEnabled,
              organization[0].pickupCutoffTime,
              organization[0].pickupNotePlaceholder,
              organization[0].busExcusesEnabled
            );

            resolve(res);
          },
          (error) => {
            console.log('error', error);
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /*
    Gets the details of the selected organization
    */
  getOrganizationByIDForOrgAdmin(): Promise<Organization> {
    let res: Organization;
    const organizationID = localStorage.getItem('spareSelectedOrganization');
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Organizations/admin/organization/GetByID';
      this.httpClient
        .get<any>(url, {
          params: new HttpParams().set('organizationID', '' + organizationID),
        })
        .subscribe(
          (organization: any) => {
            const currency: Currency = {
              currencyID: organization[0].currencyID,
              currencyCode: organization[0].currencyCode,
              longName: organization[0].currencyLongName,
              precision: organization[0].currencyPrecision,
            };

            const gateway: Gateway = {
              gatewayName: organization[0].gatewayName,
              internalGatewayName: organization[0].internalGatewayName,
              gatewayInternalCode: organization[0].gatewayInternalCode,
              gatewayID: organization[0].gatewayID,
            };

            res = new Organization(
              organization[0].organizationID,
              organization[0].organizationName,
              organization[0].organizationShortName,
              organization[0].lowBalanceAmount,
              organization[0].logoURL,
              organization[0].colorCode,
              organization[0].requiresVerification,
              organization[0].handlesBilling,
              organization[0].transactionPINDisabled,
              organization[0].accessNotificationsEnabled,
              organization[0].paymentRequestNotificationsEnabled,
              organization[0].busNotificationsEnabled,
              organization[0].canteenMenusAvailableInApp,
              organization[0].acceptsCash,
              currency,
              gateway,
              organization[0].timeZoneID,
              organization[0].timeZoneDatabaseName,
              organization[0].legacyPairingFlow,
              organization[0].hidden,
              organization[0].enableLimits,
              organization[0].enableAllergens,
              organization[0].enableFees,
              organization[0].enableOnlinePickup,
              organization[0].enableProductRestrictions,
              organization[0].promptToLinkAfterClaiming,
              organization[0].postClaimingURL,
              organization[0].selfRegistration,
              organization[0].autoGenerateStudentID,
              organization[0].balanceDescription,
              organization[0].allowSchedulingOnAnotherBus,
              organization[0].autoPairOnParentPhoneNumberMatch,
              organization[0].newOnlineStoreEnabled,
              organization[0].pickupCutoffTime,
              organization[0].pickupNotePlaceholder,
              organization[0].busExcusesEnabled
            );

            resolve(res);
          },
          (error) => {
            console.log('error', error);
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  /*
    Add the organization image to
    the spaces then adds the organization to the database
    Afterwards after receiving the id of the newly created organization
     the email identifiers are linked to the organization
    */
  addOrganization(
    organizationName: string,
    organizationShortName: string,
    lowBalanceAmount: number,
    logoURL: any,
    colorCode: string,
    requiresVerification: boolean,
    handlesBilling: boolean,
    transactionPINDisabled: boolean,
    accessNotificationsEnabled: boolean,
    paymentRequestNotificationsEnabled: boolean,
    busNotificationsEnabled: boolean,
    canteenMenusAvailableInApp: boolean,
    acceptsCash: boolean,
    currencyID: number,
    timeZoneID: number,
    legacyPairingFlow: boolean,
    hidden: boolean,
    enableLimits: boolean,
    enableAllergens: boolean,
    enableFees: boolean,
    enableOnlinePickup: boolean,
    enableProductRestrictions: boolean,
    promptToLinkAfterClaiming: boolean,
    postClaimingURL: string,
    selfRegistration: boolean,
    autoGenerateStudentID: boolean,
    balanceDescription: string,
    allowSchedulingOnAnotherBus: boolean,
    autoPairOnParentPhoneNumberMatch: boolean,
    newOnlineStoreEnabled: boolean,
    pickupCutoffTime: string,
    pickupNotePlaceholder: string,
    busExcusesEnabled: boolean
  ) {
    const url: string = this.baseUrl + '/Organizations/Admin/Add';
    const source$ = this.httpClient.post(url, {
      organizationName,
      organizationShortName,
      lowBalanceAmount,
      logoURL,
      colorCode,
      requiresVerification: requiresVerification,
      handlesBilling: handlesBilling,
      transactionPINDisabled,
      accessNotificationsEnabled,
      paymentRequestNotificationsEnabled,
      busNotificationsEnabled,
      canteenMenusAvailableInApp,
      acceptsCash,
      currencyID,
      timeZoneID,
      legacyPairingFlow,
      hidden,
      enableLimits,
      enableAllergens,
      enableFees,
      enableOnlinePickup,
      enableProductRestrictions,
      promptToLinkAfterClaiming,
      postClaimingURL,
      selfRegistration,
      autoGenerateStudentID,
      balanceDescription,
      allowSchedulingOnAnotherBus,
      autoPairOnParentPhoneNumberMatch,
      newOnlineStoreEnabled,
      pickupCutoffTime,
      pickupNotePlaceholder,
      busExcusesEnabled
    });

    return lastValueFrom(source$);
  }

  /*
    First if the image is changed then the api call is made to upload the new image to spaces
    Then the organization is updated with the new data
    Afterwards the email identifiers is checked to remove the ones that no longer exists
    and add new ones that didnot previously exist
    */
  updateOrganization(
    organizationName: string,
    organizationShortName: string,
    lowBalanceAmount: number,
    logoURL: any,
    colorCode: string,
    requiresVerification: boolean,
    handlesBilling: boolean,
    transactionPINDisabled: boolean,
    accessNotificationsEnabled: boolean,
    paymentRequestNotificationsEnabled: boolean,
    busNotificationsEnabled: boolean,
    canteenMenusAvailableInApp: boolean,
    acceptsCash: boolean,
    timeZoneID,
    legacyPairingFlow: boolean,
    hidden: boolean,
    enableLimits: boolean,
    enableAllergens: boolean,
    enableFees: boolean,
    enableOnlinePickup: boolean,
    enableProductRestrictions: boolean,
    promptToLinkAfterClaiming: boolean,
    postClaimingURL: string,
    selfRegistration: boolean,
    autoGenerateStudentID: boolean,
    balanceDescription: string,
    allowSchedulingOnAnotherBus: boolean,
    autoPairOnParentPhoneNumberMatch: boolean,
    newOnlineStoreEnabled: boolean,
    pickupCutoffTime: string | null,
    pickupNotePlaceholder: string,
    busExcusesEnabled: boolean
  ) {
    const url: string = this.baseUrl + `/Organizations/${this.selectedOrganization}/Admin/Update`;
    const source$ = this.httpClient.patch(url, {
      organizationName,
      organizationShortName,
      lowBalanceAmount,
      logoURL,
      colorCode,
      requiresVerification,
      handlesBilling,
      transactionPINDisabled,
      accessNotificationsEnabled,
      paymentRequestNotificationsEnabled,
      busNotificationsEnabled,
      canteenMenusAvailableInApp,
      acceptsCash,
      timeZoneID,
      legacyPairingFlow,
      hidden,
      enableLimits,
      enableAllergens,
      enableFees,
      enableOnlinePickup,
      enableProductRestrictions,
      promptToLinkAfterClaiming,
      postClaimingURL,
      selfRegistration,
      autoGenerateStudentID,
      balanceDescription,
      allowSchedulingOnAnotherBus,
      autoPairOnParentPhoneNumberMatch,
      newOnlineStoreEnabled,
      pickupCutoffTime,
      pickupNotePlaceholder,
      busExcusesEnabled
    });

    return lastValueFrom(source$);
  }

  /*
    Create a new link between a branch and an organization
    */
  addBranch(branch: number) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Organizations/Branches/Admin/Add';
      this.httpClient
        .post(url, {
          organizationID: this.selectedOrganization,
          branchID: branch,
        })
        .subscribe(
          () => {
            resolve('success');
            this.toastService.show('success', 'Success', 'Branch added successfully');
          },
          (error) => {
            this.routeService.checkErr(error).then((err: any) => {
              reject(err);
              this.toastService.show('danger', 'Error', err.err);
            });
          }
        );
    });
  }

  getBranchByMerchant(merchantID: number, filterOnlyNotInOrganization = false) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl + `/Branches/Admin/Merchant/?merchantID=${encodeURI(merchantID.toString())}&filterOnlyNotInOrganization=${filterOnlyNotInOrganization}`;
      const branches: any[] = [];
      this.httpClient.get<any>(url).subscribe(
        (branchesData: any) => {
          for (const branch of branchesData) {
            const object = {
              branchID: branch.branchID,
              branchName: branch.branchName,
            };
            branches.push(object);
          }
          resolve(branches);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getAllCurrencies(): Promise<Currency[]> {
    return this.httpClient
      .get(`${this.baseUrl}/currency`)
      .pipe(
        catchError(this._errorHandler),
        map((currencies: any[]) => currencies.map(_currencyMapper))
      )
      .toPromise();

    function _currencyMapper(c: any): Currency {
      return {
        currencyCode: c.currencyCode,
        currencyID: c.currencyID,
        countryCode: c.countryCode,
        longName: c.longName,
        precision: c.currencyPrecision,
      };
    }
  }

  addNewCurrency(body: {
    currencyLongName: string;
    currencyCode: string;
    currencyPrecision: number;
  }): Promise<any> {
    return this.httpClient
      .post(`${this.baseUrl}/currency`, body)
      .pipe(catchError(this._errorHandler))
      .toPromise();
  }

  updateCurrency(c: Currency): Promise<any> {
    return this.httpClient
      .put(`${this.baseUrl}/currency`, {
        currencyID: c.currencyID,
        currencyLongName: c.longName,
        countryCode: c.countryCode,
      })
      .pipe(catchError(this._errorHandler))
      .toPromise();
  }

  async getFamilyBalances(asOfDate: string): Promise<any> {
    try {
      const organizationID = localStorage.getItem('spareSelectedOrganization');
      const url = `${this.baseUrl}/family-account/balances/organization/?asOfDate=${asOfDate}&organizationID=${organizationID}`;
      return await lastValueFrom(this.httpClient.get(url));
    } catch (err) {
      const error = await this.routeService.checkErr(err);
      this.toastService.show('danger', 'Error', error.err);
    }
  }

  /**
   * Parsing error using route service and then show the error toast.
   * @param error
   * @private
   */
  private _errorHandler(error) {
    this.routeService.checkErr(error).then((err: any) => {
      this.toastService.show('danger', 'Error', err.err);
    });
    return throwError(error);
  }
}
