import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SearchModalService } from './page-search.service';

import { MenuItemsBuilder } from '../../../pages/pages-menu';
import { AutoComplete } from 'primeng/autocomplete';


import { MerchantOrganizationService } from '../../../pages/organization/organizations/organization.service';
import { AuthService } from '../../../auth/auth.service';
import { merchantPermissions } from '../../../utils/permissions';


@Component({
  selector: 'spare-page-search',
  templateUrl: './page-search.component.html',
  styleUrls: ['./page-search.component.scss']
})
export class PageSearchComponent implements OnInit {
  @ViewChild('searchInput') searchInput: AutoComplete;
  displayModal: boolean = false;
  pages: any[] = []; // You should populate this with the available pages and their permissions
  availableActions: any[] = [];
  availableActionsBySearch: any[] = [];
  selectedPage: any;
  currentAction: 'switchView' | 'navigate' | 'supportSearch' | 'enterSearchTerm' | 'enterSearchValue' = 'navigate';

  currentMerchant;
  currentOrganization;

  availableEntities: any[] = [];

  keyboardSubscription: any;

  availableSupportSearchOptions: any[] = [
    {
      title: 'Phone',
      value: 'phoneNo',
      icon: 'phone-outline',
    },
    {
      title: 'Email',
      value: 'email',
      icon: 'email-outline',
    },
    {
      title: 'User ID',
      value: 'userID',
      icon: 'person-outline',
    },
    {
      title: 'External Peripheral ID',
      value: 'externalPeripheralID',
      icon: 'hash-outline',
    },
    {
      title: 'Child ID',
      value: 'childID',
      icon: 'people-outline',
    },
    {
      title: 'External Student ID',
      value: 'externalStudentID',
      icon: 'home-outline',
    },
    {
      title: 'External User ID',
      value: 'externalUserID',
      icon: 'keypad-outline',
    },
  ];

  searchingBySupport: string;

  token;
  constructor(
    private searchModalService: SearchModalService,
    private router: Router,
    private organizationService: MerchantOrganizationService,
    public authService: AuthService
  ) {

  }

  async ngOnInit() {
    await this.subscribeToDestroyEvent();
    await this.subscribeToBuildEvent();
    this.token = localStorage.getItem('spareToken');
    if (!this.token) {
      return;
    }

    await this.buildComponent();
  }

  async buildComponent() {
    this.subscribeToModalOpenEvent();
    this.buildMenu();
    this.flattenPages(this.pages);
    this.addCustomActions();
    this.subscribeToKeyboardEvent();
    await this.getAvailableEntities();
  }

  subscribeToDestroyEvent() {
    this.searchModalService.destroyComponent.subscribe(() => {
      this.displayModal = false;
      this.availableActions = [];
      this.availableEntities = [];
      this.availableActionsBySearch = [];
      this.searchingBySupport = '';
      this.currentAction = 'navigate';
    });
  }

  subscribeToBuildEvent() {
    this.searchModalService.buildComponent.subscribe(() => {
      this.buildComponent();
    });
  }

  subscribeToModalOpenEvent() {
    this.searchModalService.openModal$.subscribe(() => {
      this.showModal();
    });
  }


  subscribeToKeyboardEvent() {
    this.keyboardSubscription = window.addEventListener('keydown', (event) => {
      if (!this.displayModal) {
        return;
      }

      if (event.key === 'Escape') {
        event.preventDefault();
        this.closeModal();
      }
    });
  }

  buildMenu() {
    const menuBuilder: MenuItemsBuilder = new MenuItemsBuilder();
    this.pages = menuBuilder.getMenuItems().filter((page) => {
      return page.hidden !== true;
    });
  }

  async getAvailableEntities() {
    const {orgs, currentOrg} = await this.loadOrganizations();
    const {merchants, currentMerchant} = await this.loadMerchants();

    console.log('currentOrg', currentOrg);
    console.log('orgs', orgs);
    if (currentMerchant) {
      this.availableEntities.push(...currentMerchant);
    }

    if (currentOrg) {
      this.availableEntities.push(...currentOrg);
    }


    if (orgs) {
      this.availableEntities.push(...orgs);
    }

    if (merchants) {
      this.availableEntities.push(...merchants);
    }


    // this.availableEntities.push(...currentMerchant, ...currentOrg, ...orgs, ...merchants);
  }

  async loadOrganizations() {
    const orgs = await this.organizationService.getOrganizations(true);
    const currentOrg = localStorage.getItem('spareSelectedOrganization');
    let currentOrgObj;

    if (currentOrg) {
      this.currentOrganization = currentOrg[0];
      currentOrgObj = orgs.filter((org) => org.organizationID === +currentOrg);
      currentOrgObj[0].current = true;
    }
    const filteredOrgs = orgs.filter((org) => org.organizationID !== +currentOrg);

    return {orgs: filteredOrgs, currentOrg: currentOrgObj};
  }

  async loadMerchants() {
    let merchants;
    const currentMerchant: any = await this.authService.getMerchant();
    const currentMerchantObj = [{ merchantID: currentMerchant.merchantID, merchantName: currentMerchant.name, merchantLogo: currentMerchant.colorLogoURL, current: true }];
    this.currentMerchant = currentMerchantObj;

    if (this.authService.hasPermissionMerchant(merchantPermissions.SUPER_ADMIN + merchantPermissions.CHANGE_MERCHANT)) {
      merchants = await this.authService.getMerchantsWithID();
      merchants = merchants.filter((merchant) => merchant.merchantID != currentMerchant.merchantID);
    }

    return {merchants, currentMerchant: currentMerchantObj};
  }

  addCustomActions() {
    if (this.canChangeView()) {
      this.availableActions.unshift({
        title: 'Switch View',
        customAction: 'switchView',
        icon: 'sync-outline',
      });

    }

    if (this.authService.hasPermissionMerchant(merchantPermissions.SUPER_ADMIN)) {
      this.availableActions.push({
        title: 'Copy Token',
        customAction: 'copyToken',
        icon: 'copy-outline',
      })
    }

    if (this.authService.hasPermissionMerchant(merchantPermissions.SUPER_ADMIN + merchantPermissions.SPARE_SUPPORT + merchantPermissions.SPARE_SUPER_SUPPORT)) {
      this.availableActions = this.availableActions.filter((action) => {
        return action.title !== 'Support';
      });
      this.availableActions.push({
        title: 'Search for User (Support)',
        icon: 'search',
        customAction: 'supportSearch',
      });
    }

    this.availableActions.push({
      title: 'Logout',
      link: '/auth/logout',
      icon: 'log-out-outline',
    })
  }

  flattenPages(pages) {
    for (const page of pages) {
      if (page.hidden) {
        continue;
      }

      if (page.link) {
        this.availableActions.push(page);
        continue;
      }

      if (page.children) {
        for (const child of page.children) {
          child.title = `${page.title} - ${child.title}`;
        }
        this.flattenPages(page.children);
      }
    }
  }

  search(event) {
    if (this.currentAction === 'switchView') {
      this.availableActionsBySearch = this.filterEntities(event.query);
    }

    if (this.currentAction === 'navigate') {
      this.availableActionsBySearch = this.filterPages(event.query);
    }

    if (this.currentAction === 'supportSearch') {
      this.availableActionsBySearch = this.filterSupportSearchOptions(event.query);
    }

    if (this.currentAction === 'enterSearchValue') {
      this.availableActionsBySearch = [{
        title: `Search by ${this.searchingBySupport}: ${event.query}`,
        icon: 'headphones-outline',
        value: event.query,
      }];
    }
  }

  filterSupportSearchOptions(query) {
    if (query === '') {
      return this.availableSupportSearchOptions;
    }

    return this.availableSupportSearchOptions.filter((option) => {
      return option.title.toLowerCase().includes(query.toLowerCase());
    });
  }

  filterEntities(query) {
    if (query === '') {
      return this.availableEntities;
    }

    return this.availableEntities.filter((entity) => {
      let searchTerm = '';
      if (entity.merchantID) {
        searchTerm = `${entity.merchantName} ${entity.merchantID}`;
      } else {
        searchTerm = `${entity.organizationName} ${entity.organizationShortName} ${entity.organizationID}`;
      }
      return searchTerm.toLowerCase().includes(query.toLowerCase());
    });
  }

  filterPages(query) {
    return this.availableActions.filter((page) => {
      return page.title.toLowerCase().includes(query.toLowerCase());
    });
  }

  async onSelect(action) {
    if (action.customAction) {
      if (action.customAction === 'switchView') {
        this.onChangeView();
        return;
      }

      if (action.customAction === 'copyToken') { 
        this.copyToken();
        return;
      }

      if (action.customAction === 'supportSearch') {
        this.onSupportSearch();
        return;
      }
    }

    if (this.currentAction === 'supportSearch') {
      this.setSearchTerm(action.value);
      return;
    }

    if (this.currentAction === 'enterSearchValue') {
      this.goToSupport(this.searchingBySupport, action.value);
      return;
    }

    if (this.currentAction === 'switchView') {
      //do something here
      await this.setMerchantOrganization(action);
      return;
    } 
    
    if (this.currentAction === 'navigate') {
      this.router.navigate([action.link]);
    }

    this.closeModal();
  }

  copyToken() {
    const token = localStorage.getItem('spareToken');
    navigator.clipboard.writeText(token);
    this.closeModal();
  }

  onAfterShow(event) {
    this.searchInput.focusInput();
    this.searchInput.search(null, '');
  }

  showModal() {
    const token = localStorage.getItem('spareToken');
    if (!token) {
      return;
    }
    this.displayModal = true;
  }

  async setMerchantOrganization(merchantOrganization) {
    if (merchantOrganization.merchantID) {
      await this.authService.setView(null, null, merchantOrganization, this.currentMerchant[0]);
    } else if (merchantOrganization.organizationID) {
      await this.authService.setView(merchantOrganization, this.currentOrganization, null, null);
    }
  }

  setSearchTerm(searchBy) {
    console.log('setting search term', searchBy);
    this.searchingBySupport = searchBy;
    this.currentAction = 'enterSearchValue';
    this.searchInput.clear();
    this.searchInput.search(null, '');
  }

  goToSupport(searchBy, searchValue) {
    console.log('going to support', searchBy, searchValue);
    this.router.navigate(['pages/super/support/support'], {queryParams: {searchBy, searchValue}});
    this.searchingBySupport = '';
    this.currentAction = 'navigate';
    this.closeModal();
  }

  onSupportSearch() {
    this.currentAction = 'supportSearch';
    this.searchInput.clear();
    this.searchInput.search(null, '');
  }

  onChangeView() {
    this.currentAction = 'switchView';
    this.searchInput.clear();
    this.searchInput.search(null, '');
  }

  canChangeView() {
    return localStorage.getItem('spareRoles') != '';
  }

  closeModal() {
    this.searchInput.clear();
    if (this.currentAction !== 'navigate') {
      this.currentAction = 'navigate';
      this.searchingBySupport = '';
      return;
    }

    this.currentAction = 'navigate';
    this.displayModal = false;
  }
}
