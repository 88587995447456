import { Currency } from './Currency.model';
import { Gateway } from '../../gateway/Gateway.model';

export class Organization {
  organizationID: number;
  organizationName: string;
  organizationShortName: string;
  lowBalanceAmount: number;
  logoURL: string;
  colorCode: string;
  requiresVerification: boolean;
  collapsed: boolean;
  handlesBilling: boolean;
  transactionPINDisabled: boolean;
  accessNotificationsEnabled: boolean;
  paymentRequestNotificationsEnabled: boolean;
  busNotificationsEnabled: boolean;
  canteenMenusAvailableInApp: boolean;
  acceptsCash: boolean;
  currency: Currency;
  gateway: Gateway;
  timeZoneID: number;
  timeZoneDatabaseName;
  legacyPairingFlow: boolean;
  hidden: boolean;
  enableLimits: boolean;
  enableAllergens: boolean;
  enableFees: boolean;
  enableOnlinePickup: boolean;
  enableProductRestrictions: boolean;
  promptToLinkAfterClaiming: boolean;
  postClaimingURL: string;
  selfRegistration: boolean;
  autoGenerateStudentID: boolean;
  balanceDescription: string;
  branches?: any[];
  branchesLoaded: boolean = false;
  allowSchedulingOnAnotherBus: boolean;
  autoPairOnParentPhoneNumberMatch: boolean;
  newOnlineStoreEnabled: boolean;
  pickupCutoffTime: string;
  pickupNotePlaceholder: string;
  busExcusesEnabled: boolean;

  constructor(
    organizationID: number,
    organizationName: string,
    organizationShortName: string,
    lowBalanceAmount: number,
    logoURL: string,
    colorCode: string,
    requiresVerification: boolean,
    handlesBilling: boolean,
    transactionPINDisabled: boolean,
    accessNotificationsEnabled: boolean,
    paymentRequestNotificationsEnabled: boolean,
    busNotificationsEnabled: boolean,
    canteenMenusAvailableInApp: boolean,
    acceptsCash: boolean,
    currency: Currency,
    gateway: Gateway,
    timeZoneID: number,
    timeZoneDatabaseName,
    legacyPairingFlow: boolean,
    hidden: boolean,
    enableLimits: boolean,
    enableAllergens: boolean,
    enableFees: boolean,
    enableOnlinePickup: boolean,
    enableProductRestrictions: boolean,
    promptToLinkAfterClaiming: boolean,
    postClaimingURL: string,
    selfRegistration: boolean,
    autoGenerateStudentID: boolean,
    balanceDescription: string,
    allowSchedulingOnAnotherBus: boolean,
    autoPairOnParentPhoneNumberMatch: boolean,
    newOnlineStoreEnabled: boolean,
    pickupCutoffTime: string,
    pickupNotePlaceholder: string,
    busExcusesEnabled: boolean
  ) {
    this.organizationID = organizationID;
    this.organizationName = organizationName;
    this.organizationShortName = organizationShortName;
    this.lowBalanceAmount = lowBalanceAmount;
    this.logoURL = this.checkURL(logoURL);
    this.colorCode = colorCode;
    this.requiresVerification = requiresVerification;
    this.collapsed = true;
    this.handlesBilling = handlesBilling;
    this.transactionPINDisabled = transactionPINDisabled;
    this.accessNotificationsEnabled = accessNotificationsEnabled;
    this.paymentRequestNotificationsEnabled = paymentRequestNotificationsEnabled;
    this.canteenMenusAvailableInApp = canteenMenusAvailableInApp;
    this.acceptsCash = acceptsCash;
    this.currency = currency;
    this.gateway = gateway;
    this.timeZoneID = timeZoneID;
    this.timeZoneDatabaseName = timeZoneDatabaseName;
    this.busNotificationsEnabled = busNotificationsEnabled;
    this.legacyPairingFlow = legacyPairingFlow;
    this.hidden = hidden;
    this.enableLimits = enableLimits;
    this.enableAllergens = enableAllergens;
    this.enableFees = enableFees;
    this.enableOnlinePickup = enableOnlinePickup;
    this.enableProductRestrictions = enableProductRestrictions;
    this.promptToLinkAfterClaiming = promptToLinkAfterClaiming;
    this.postClaimingURL = postClaimingURL;
    this.selfRegistration = selfRegistration;
    this.autoGenerateStudentID = autoGenerateStudentID;
    this.balanceDescription = balanceDescription;
    this.allowSchedulingOnAnotherBus = allowSchedulingOnAnotherBus;
    this.autoPairOnParentPhoneNumberMatch = autoPairOnParentPhoneNumberMatch;
    this.newOnlineStoreEnabled = newOnlineStoreEnabled;
    this.pickupCutoffTime = pickupCutoffTime;
    this.pickupNotePlaceholder = pickupNotePlaceholder;
    this.busExcusesEnabled = busExcusesEnabled;
  }

  checkURL(url: string) {
    if (url && (url.startsWith('http') || url.startsWith('https'))) {
      return url;
    } else {
      return 'https://' + url;
    }
  }
}
