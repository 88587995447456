export const merchantPermissions = {
  SUPER_ADMIN: 1,
  STORE_ADMIN: 2,
  CHANGE_MERCHANT: 4,
  ALTER_ADMINS_SUPER: 8,
  ALTER_BRANCHES: 16,
  VIEW_BRANCHES: 32,
  MANUAL_OVERRIDES: 64,
  ALTER_MERCHANTS: 128,
  VIEW_BILLS: 256,
  VIEW_SHIFTS: 512,
  VIEW_CASHIERS: 1024,
  ALTER_CASHIERS: 2048,
  VIEW_MACHINES: 4096,
  BLOCK_MACHINES: 8192,
  ALTER_MACHINES_SUPER: 16384,
  REGISTER_CHILDREN: 32768,
  VIEW_INVENTORY: 65536,
  ALTER_INVENTORY: 131072,
  RECORD_BILLS_PAID: 262144,
  VIEW_MERCHANTS: 524288,
  ALTER_ADMINS: 1048576,
  ALTER_ORGANIZATION: 2097152,
  VIEW_ORGANIZATIONS: 4194304,
  VIEW_PURCHASES: 16777216,
  ALTER_PURCHASES: 33554432,
  REVERSE_PURCHASES: 67108864,
  SPARE_SUPPORT: 8388608,
  SPARE_SUPER_SUPPORT: 268435456
};

export const organizationPermissions = {
  ORGANIZATION_SUPER_ADMIN: 1,
  ORGANIZATION_ADMIN: 2,
  ORGANIZATION_VIEW_BILLS: 4,
  ORGANIZATION_RECORD_BILLS: 8,
  ORGANIZATION_VIEW_BRANCHES: 16,
  ORGANIZATION_VIEW_USERS: 32,
  ORGANIZATION_VIEW_ATTENDANCE: 64,
  ORGANIZATION_ALTER_ATTENDANCE: 128,
  ORGANIZATION_ADMIN_ADD_CHILD: 256,
  ORGANIZATION_VIEW_NEWS_FEED: 512,
  ORGANIZATION_ALTER_NEWS_FEED: 1024,
  ORGANIZATION_ALTER_OVERDRAFT: 2048,
  ORGANIZATION_VIEW_BUS_SYSTEM: 4096,
  ORGANIZATION_ALTER_BUS_SYSTEM: 8192,
  ORGANIZATION_EXPORT_DATA: 16384,
  ORGANIZATION_CAMPUS_RECORD_ATTENDANCE: 32768,
  ORGANIZATION_DEACTIVATE_CHILD: 65536,
  ORGANIZATION_CAMPUS_MONITOR_ATTENDANCE: 131072,
  ORGANIZATION_ALTER_GATES: 262144,
  VIEW_OWN_VENDOR_SALES: 524288,
  VIEW_SCHOOL_FEES: 1048576,
  ALTER_SCHOOL_FEES: 2097152,
  ORGANIZATION_MANAGE_INTEGRATIONS: 4194304,
  ORGANIZATION_VIEW_DIGITAL_IDS: 8388608,
  ORGANIZATION_ALTER_DIGITAL_IDS: 16777216,
  ORGANIZATION_VIEW_PICKUP_REQUESTS: 33554432,
  ORGANIZATION_ALTER_PICKUP_REQUESTS: 67108864,
  ORGANIZATION_VIEW_GATES: 134217728,
};
