import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../route.service';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import * as moment from 'moment-timezone';
import { lastValueFrom } from 'rxjs';

interface TimeZoneOffset {
  offset: string;
  zones: string[];
}

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {
  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {
    this.utcOffsetsAndZones = this.getUtcOffsets();
  }

  baseUrl: string = environment.baseUrl;
  TIME_ZONE_DB_NAME_LOCAL_NAME = 'organizationTimeZoneDBName';
  public utcOffsetsAndZones: TimeZoneOffset[] = [];

  getData() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/time-zone';
      this.httpClient.get<any>(url).subscribe(
        (timeZones: any) => {
          resolve(timeZones);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  addTimeZone(timeZoneName, timeZoneDatabaseName, country) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/time-zone';
      const body = { timeZoneName, timeZoneDatabaseName, country };
      const response = this.httpClient.post(url, body);
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Time Zone was added successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updateTimeZone(timeZoneID, timeZoneName, timeZoneDatabaseName, country) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/time-zone';
      const body = { timeZoneID, timeZoneName, timeZoneDatabaseName, country };
      const response = this.httpClient.put(url, body);
      response.subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Time Zone was updated successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  checkIfClientHasCorrectTimezoneAndTimeSettings(offset?: string, checkTime = true) {
    const localTimeZone = offset? this.getZoneFromOffset(offset) : moment.tz.guess();
    const payload: {localTimeZone: string, checkTime?: boolean, localTime?: string} = {
      localTimeZone,
      checkTime
    }
    if(checkTime){
      const localTime = moment().toISOString();
      payload.localTime = localTime;
    }

    const url = `${this.baseUrl}/time-zone/check-settings`;
    return lastValueFrom(this.httpClient.get(url, { params: payload }));
  }

  getZoneFromOffset(offset: string, index = 0) {
    return this.utcOffsetsAndZones.find(utcOffset => utcOffset.offset === offset)?.zones[index];
  }

  getCachedTimeZoneDBName(): string | null {
    return localStorage.getItem(this.TIME_ZONE_DB_NAME_LOCAL_NAME);
  }

  setTimeZoneDBNameInCache(tz: string) {
    localStorage.setItem(this.TIME_ZONE_DB_NAME_LOCAL_NAME, tz);
  }

  getUtcOffsets() {
    const zones = moment.tz.names();
    const offsetMap = new Map<string, string[]>();
  
    for (const zone of zones) {
      const offset = this.getOffsetFromZone(zone);
      if (!offsetMap.has(offset)) {
        offsetMap.set(offset, []);
      }
      offsetMap.get(offset)?.push(zone);
    }
  
    // Convert map entries to array and sort by offset
    return Array.from(offsetMap.entries())
      .sort((a, b) => {
        const offsetA = Number.parseInt(a[0].slice(3, 6)) * 60 + Number.parseInt(a[0].slice(7));
        const offsetB = Number.parseInt(b[0].slice(3, 6)) * 60 + Number.parseInt(b[0].slice(7));
        return offsetA - offsetB;
      })
      .map(entry => ({ 
        offset: entry[0], 
        zones: entry[1] 
      }));
  }

  getClientUTCOffset() {
    const currentOffset = moment.tz.guess();
    return this.getOffsetFromZone(currentOffset);
  };

  getOffsetFromZone(zone: string) {
    const offsetMinutes = moment.tz(zone).utcOffset();
    const sign = offsetMinutes >= 0 ? "+" : "-";
    const hours = Math.floor(Math.abs(offsetMinutes) / 60)
      .toString()
      .padStart(2, "0");
    const minutes = (Math.abs(offsetMinutes) % 60)
      .toString()
      .padStart(2, "0");
    return `UTC${sign}${hours}:${minutes}`;
  }
}
