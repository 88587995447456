import { Component, OnInit, HostListener } from '@angular/core';
import { SearchModalService } from './ui/spare-components/page-search/page-search.service';
import { AnalyticsService } from './@core/utils/analytics.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'spare-app',
  template: '<spare-page-search></spare-page-search><router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(
    private analytics: AnalyticsService,
    private primeConfig: PrimeNGConfig,
    private searchModalService: SearchModalService,
  ) {}

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
      event.preventDefault();
      this.searchModalService.triggerOpenModal();
    }
  }

  ngOnInit() {
    this.analytics.trackPageViews();

    this.primeConfig.ripple = false;
    // this.primeConfig.filterMatchModeOptions = {
    //   text: ['contains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
    //   number: ['equals', 'lessThan', 'greaterThan', 'notEquals', 'in', 'notIn'],
    //   date: ['equals', 'lessThan', 'greaterThan', 'notEquals', 'in', 'notIn'],
    // };
  }
}
