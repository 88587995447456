import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RouteService } from '../../../route.service';
import { ToastService } from '../../../toast.service';
import { Child } from './child.model';
import { environment } from '../../../../environments/environment';
import { Transaction } from '../../billing/organizationBilling/cashless-transactions/transactions.model';
import * as moment from 'moment';
import { AttendanceItem } from './attendanceItem.model';
import { AttendanceReport } from './attendanceReport.model';
import { AttendanceSummary } from './attendanceSummary.model';
import { map, lastValueFrom } from 'rxjs';
import { PickupActivity } from './pickupActivity.modal';
import { PickupRequest } from './pickupRequest.modal';

@Injectable()
export class AttendanceService {
  childrenMap = new Map();
  selectedChild: Child;
  selectedOrganization: number;
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService
  ) {}

  getChildren(organizationID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Child/Admin/All?organizationID=' + organizationID;
      const response = this.httpClient.get(url);
      response.subscribe(
        (childrenData: any) => {
          const children: Child[] = [];
          const staff: Child[] = [];
          const allUsers: Child[] = [];
          const grades = {};

          for (const child of childrenData) {
            const tempChild: Child = new Child(
              child.childID,
              child.fullName,
              child.externalStudentID,
              child.organizationID,
              child.className,
              child.grade,
              child.fatherPhone,
              child.motherPhone,
              child.userID,
              child.childPhone,
              child.fatherName,
              child.motherName,
              child.division,
              child.stage,
              child.externalPeripheralID,
              child.connectionID,
              child.isActive,
              child.isPaired,
              child.balance,
              child.amountWithoutPin,
              child.requirePinUnderX,
              child.spendingLimitWeekly,
              child.spendingLimitDaily,
              child.spendingLimitMonthly,
              child.userFirstName,
              child.userLastName,
              child.connectedSince,
              child.currencyCode,
              child.currencyLongName,
              moment(child.registrationDate).format('YYYY-DD-MM hh:mm:ss a'),
              child.deactivationUUID,
              child.lastCheckInTimestampToday,
              child.lastGateCheckedInAtToday,
              child.guardianRequired
            );

            this.childrenMap.set(child.childID, tempChild);

            if (!grades[tempChild.grade.toLowerCase()]) grades[tempChild.grade.toLowerCase()] = 1;
            else grades[tempChild.grade.toLowerCase()]++;

            //push to all users
            allUsers.push(tempChild);

            //push to specific arrays for children and staff
            if (
              (tempChild.grade && tempChild.grade.includes('Staff')) ||
              (tempChild.division && tempChild.division.includes('Staff'))
            ) {
              staff.push(tempChild);
            } else {
              children.push(tempChild);
            }
          }
          resolve({ children, staff, grades, allUsers });
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  getAllAccessForChild(childID, date?: moment.Moment) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl +
        '/OrganizationAccess/Admin/ByChild/?childID=' +
        encodeURI(childID) +
        '&limit=' +
        encodeURI('50') +
        '&organizationID=' +
        encodeURI(String(this.selectedOrganization)) +
        (date ? '&date=' + encodeURI(date.format('YYYY-MM-DD')) : '');
      const response = this.httpClient.get(url);
      response.subscribe(
        (accessArray: any) => {
          resolve(accessArray);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  recordAccess(accessTime, childID, accessType, organizationID) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/OrganizationAccess/Admin/Record';
      const response = this.httpClient.post(url, {
        accessTime,
        accessType,
        childID,
        organizationID,
      });
      response.subscribe(
        (res: any) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Attendence Recorded');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  removeUserID() {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Child/Admin/RemoveUserID';
      const response = this.httpClient.post(url, {
        childID: this.selectedChild.childID,
      });
      response.subscribe(
        (res: any) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'UserID removed from child');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  setPeripheralStatus() {
    const isActive = this.selectedChild.isActive;
    const req: any = {
      connectionID: this.selectedChild.connectionID,
    };
    if (!isActive) {
      req.isActive = 1;
    } else {
      req.isActive = 0;
    }

    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Peripherals/Admin/setPeripheralStatus';
      const response = this.httpClient.post(url, req);
      response.subscribe(
        (res: any) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Peripheral status updated');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updateChild(
    childID,
    fullName,
    externalStudentID,
    grade,
    fatherPhone,
    motherPhone,
    childPhone,
    fatherName,
    motherName,
    division,
    stage,
    className
  ) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/family-account/child';
      const body = {
        childID: childID,
        fullName: fullName,
        externalStudentID: externalStudentID,
        // eslint-disable-next-line max-len
        organizationID: this.selectedOrganization
          ? this.selectedOrganization
          : +localStorage.getItem('spareSelectedOrganization'),
        grade: grade,
        fatherPhone: fatherPhone,
        motherPhone: motherPhone,
        childPhone: childPhone,
        fatherName: fatherName,
        motherName: motherName,
        division: division,
        stage: stage,
        className: className,
      };

      const response = this.httpClient.put(url, body);
      response.subscribe(
        (res: any) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Child Updated successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  addChild(
    fullName: string,
    externalStudentID: string,
    grade: string,
    fatherPhone: string,
    motherPhone: string,
    childPhone: string,
    fatherName: string,
    motherName: string,
    division: string,
    stage: string,
    className: string
  ) {
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/Child/Admin/Add';
      const postBody = {
        fullName: fullName,
        externalStudentID: externalStudentID,
        // eslint-disable-next-line max-len
        organizationID: this.selectedOrganization
          ? this.selectedOrganization
          : +localStorage.getItem('spareSelectedOrganization'),
        grade: grade,
        fatherPhone: fatherPhone,
        motherPhone: motherPhone,
        childPhone: childPhone,
        fatherName: fatherName,
        motherName: motherName,
        division: division,
        stage: stage,
        className: className,
      };
      const response = this.httpClient.post(url, postBody);
      response.subscribe(
        (res: any) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Child Added successfully');
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  childRequests(organizationID, childID, requestType: string) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl +
        '/payment-request/admin/all/?organizationID=' +
        encodeURI(organizationID) +
        '&childID=' +
        encodeURI(childID) +
        '&requestType=' +
        encodeURI(requestType);
      const response = this.httpClient.get(url);
      response.subscribe(
        (requests: any) => {
          resolve(requests);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  childTransactions(organizationID, userID, childID) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl +
        '/OrganizationAccess/Admin/getTransactions/?organizationID=' +
        encodeURI(organizationID) +
        '&userID=' +
        encodeURI(userID) +
        '&childID=' +
        encodeURI(childID);
      const response = this.httpClient.get(url);
      response.subscribe(
        (transactions: any) => {
          resolve(transactions);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  familyTransactions(organizationID, userID) {
    return new Promise((resolve, reject) => {
      if (!userID) return resolve([]);
      const url: string =
        this.baseUrl +
        '/TransactionsWithMerchant/Admin?organizationID=' +
        encodeURI(organizationID) +
        '&userID=' +
        encodeURI(userID);
      const response = this.httpClient.get(url);
      response.subscribe(
        (transactionData: any) => {
          const transactions: Transaction[] = [];
          for (const transaction of transactionData) {
            const tempTransaction: Transaction = new Transaction(
              transaction.childID,
              transaction.childFullName ? transaction.childFullName : '',
              `${transaction.parentFirstName} ${transaction.parentLastName}`,
              transaction.phoneNumber,
              transaction.amount,
              transaction.externalStudentID ? transaction.externalStudentID : '',
              transaction.transactionReference,
              moment(transaction.timestamp).format(`MMM DD 'YY hh:mm:ss`),
              transaction.transactionType,
              transaction.name,
              transaction.branchName,
              transaction.cashierName,
              transaction.currencyCode,
              transaction['Payment Method'],
              transaction.grade,
              transaction.division,
              transaction.className,
              transaction.stage,
            );
            transactions.push(tempTransaction);
          }
          resolve(transactions);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  childFailedTransactions(organizationID, childID) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl +
        '/failed-transactions/Admin/?organizationID=' +
        encodeURI(organizationID) +
        '&childID=' +
        encodeURI(childID);
      const response = this.httpClient.get(url);
      response.subscribe(
        (transactions: any) => {
          resolve(transactions);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  additionalInfo(organizationID, childID) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl +
        '/Child/Admin/Additional?organizationID=' +
        encodeURI(organizationID) +
        '&childID=' +
        encodeURI(childID);
      const response = this.httpClient.get(url);
      response.subscribe(
        (info: any) => {
          resolve(info);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  deactivateUser(childID, organizationID, reason) {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + `/family-account/child/deactivate`;
      const postBody = {
        childID,
        organizationID,
        reason,
      };

      const res = this.httpClient.post(url, postBody);
      res.subscribe(
        (info: any) => {
          resolve(info);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  reactivateUser(childID, organizationID, reason) {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + `/family-account/child/reactivate`;
      const postBody = {
        childID,
        organizationID,
        reason,
      };

      const res = this.httpClient.post(url, postBody);
      res.subscribe(
        (info: any) => {
          resolve(info);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAllAccessLogs(organizationID, dateRange) {
    return new Promise((resolve, reject) => {
      let url = `${this.baseUrl}/organizationaccess/admin/access/?organizationID=${encodeURI(
        organizationID
      )}`;

      let dateFrom = null,
        dateTo = null;
      if (dateRange.startDate && dateRange.endDate) {
        dateFrom = moment(dateRange.startDate).valueOf();
        dateTo = moment(dateRange.endDate).valueOf();
        if (dateFrom && dateTo)
          url += `&timeFrom=${encodeURI(dateFrom)}&timeTo=${encodeURI(dateTo)}&limit=${encodeURI(
            '250000'
          )}`;
      }

      const response = this.httpClient.get(url);
      response.subscribe(
        (accessLogs: any) => {
          const returnData = [];
          accessLogs.pop();
          for (const access of accessLogs) {
            const tempAccessItem: AttendanceItem = new AttendanceItem(
              access.accessID,
              access.adminID,
              access.childDivision,
              access.grade,
              access.stage,
              access.className,
              access.childID,
              access.childName,
              access.deviceName ? access.deviceName : access.gateName,
              access.externalStudentID,
              access.timestamp,
              access.organizationID,
              access.userID,
              access.adminName
            );

            returnData.push(tempAccessItem);
          }
          resolve(returnData);
        },
        (err) => {
          return reject(err);
        }
      );
    });
  }

  getAccessLogReport(
    date: moment.Moment
  ): Promise<{ students: AttendanceReport[]; stuff: AttendanceReport[] }> {
    const url = `${this.baseUrl}/organizations/admin/attendance-report?date=${encodeURI(
      date.format('YYYY-MM-DD')
    )}`;
    return this.httpClient
      .get<any[]>(url)
      .pipe(
        map((result) => result.map((obj) => new AttendanceReport(obj))),
        map((reports) =>
          reports.reduce(
            (grouped, report) => {
              grouped[report.grade.toLowerCase().includes('staff') ? 'stuff' : 'students'].push(
                report
              );
              return grouped;
            },
            { students: [], stuff: [] }
          )
        )
      )
      .toPromise();
  }

  getAccessLogSummary(
    from: moment.Moment,
    to: moment.Moment,
    childID?: number
  ): Promise<AttendanceSummary | AttendanceSummary[]> {
    const organizationID = localStorage.getItem('spareSelectedOrganization');
    const url = `${
      this.baseUrl
    }/OrganizationAccess/admin/access/summary?organizationID=${organizationID}&from=${from.format(
      'YYYY-MM-DD'
    )}&to=${to.format('YYYY-MM-DD')}${childID ? `&childID=${childID}` : ''}`;
    const source$ = this.httpClient.get<AttendanceSummary | AttendanceSummary[]>(url);

    return lastValueFrom(source$);
  }

  getDeactivatedUsers(organizationID) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl +
        `/family-account/child/deactivated/?organizationID=${encodeURI(organizationID)}`;
      const response = this.httpClient.get(url);

      response.subscribe(
        (childrenData: any) => {
          const returnData = [];
          for (const child of childrenData) {
            const tempChild: Child = new Child(
              child.childID,
              child.fullName,
              child.externalStudentID,
              child.organizationID,
              child.className,
              child.grade,
              child.fatherPhone,
              child.motherPhone,
              child.userID,
              child.childPhone,
              child.fatherName,
              child.motherName,
              child.division,
              child.stage,
              child.externalPeripheralID,
              child.connectionID,
              child.isActive,
              child.isPaired,
              child.balance,
              child.amountWithoutPin,
              child.requirePinUnderX,
              child.spendingLimitWeekly,
              child.spendingLimitDaily,
              child.spendingLimitMonthly,
              child.userFirstName,
              child.userLastName,
              child.connectedSince,
              child.currencyCode,
              child.currencyLongName,
              moment(child.registrationDate).format('YYYY-DD-MM hh:mm:ss a'),
              child.deactivationUUID,
              child.lastCheckInTimestampToday,
              child.lastGateCheckedInAtToday,
              child.guardianRequired,
              child.deactivatedOn
                ? moment(child.deactivatedOn).format('YYYY-DD-MM hh:mm:ss a')
                : null,
              child.deactivationReason,
              child.adminName,
              JSON.parse(child.externalPeripheralIDs)
            );

            returnData.push(tempChild);
          }
          resolve(returnData);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  markStudentRequireGuardianStatus(isRequire: boolean, childID: number): Promise<any> {
    const url = `${this.baseUrl}/Child/Admin/set-require-guardian/${childID}`;
    const body = { requireStatus: isRequire };

    return this.httpClient.put(url, body).toPromise();
  }

  getPickupActivity(from, to, status): Promise<PickupActivity[]> {
    const url = `${this.baseUrl}/organization/pickup-request`;

    const params = {
      status: status,
    };

    if (from && to) {
      params['to'] = moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      params['from'] = moment(from).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    const source$ = this.httpClient
      .get<PickupActivity[]>(url, {
        params,
      })
      .pipe(map((x) => x.map((xx) => new PickupActivity(xx))));

    return lastValueFrom(source$);
  }

  getPickupRequests(from, to, status): Promise<PickupRequest[]> {
    const organizationID = localStorage.getItem('spareSelectedOrganization');
    const url = `${this.baseUrl}/organization/pickup-request/online`;

    const params = {
      organizationID: organizationID,
      status: status,
    };

    if (from && to) {
      params['to'] = moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      params['from'] = moment(from).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    const source$ = this.httpClient
      .get<PickupRequest[]>(url, {
        params,
      })
      .pipe(map((x) => x.map((xx) => new PickupRequest(xx))));

    return lastValueFrom(source$);
  }

  approvePickupRequest(pickupOnlineRequestID: number, gateID: number): Promise<any> {
    const organizationID = localStorage.getItem('spareSelectedOrganization');
    const url = `${this.baseUrl}/organization/pickup-request/online?organizationID=${organizationID}`;
    const body = { pickupOnlineRequestID, gateID };

    const source$ = this.httpClient.put(url, body);

    return lastValueFrom(source$);
  }

  rejectPickupRequest(pickupOnlineRequestID: number, rejectionReason: string): Promise<any> {
    const organizationID = localStorage.getItem('spareSelectedOrganization');
    const url = `${this.baseUrl}/organization/pickup-request/online/reject?organizationID=${organizationID}`;
    const body = { pickupOnlineRequestID, reason: rejectionReason };

    const source$ = this.httpClient.post(url, body);

    return lastValueFrom(source$);
  }

  completePickupRequest(pickupRequestID: number): Promise<any> {
    const url = `${this.baseUrl}/organization/pickup-request/complete/`;
    const body = {
      pickupRequestIDs: [pickupRequestID],
    };

    const source$ = this.httpClient.put(url, body);

    return lastValueFrom(source$);
  }

  cancelPickupRequest(pickupRequestID: number): Promise<any> {
    const url = `${this.baseUrl}/organization/pickup-request/cancel/`;
    const body = {
      pickupRequestIDs: [pickupRequestID],
    };

    const source$ = this.httpClient.put(url, body);

    return lastValueFrom(source$);
  }
}
