import { NbMenuItem } from '@nebular/theme';
import { merchantPermissions, organizationPermissions } from '../utils/permissions';

export class MenuItemsBuilder {
  private MENU_ITEMS: { merchant: NbMenuItem[]; organization: NbMenuItem[] };
  private adminRole: number = +localStorage.getItem('spareAdminRole');
  private orgRole: number = +localStorage.getItem('spareSelectedOrganizationRole');
  private readonly canSeeManage: boolean;

  constructor() {
    this.canSeeManage = !!(
      this.adminRole & (1 + 2) ||
      (this.adminRole & (4096 + 8192) &&
        this.adminRole & (1024 + 2048) &&
        this.adminRole & (16 + 32)) ||
      (this.adminRole & (4096 + 8192) && this.adminRole & (1024 + 2048)) ||
      (this.adminRole & (4096 + 8192) && this.adminRole & (16 + 32))
    );
  }

  /**
   * return active view out of the local storage
   * @private
   */
  private static _activeView(): 'merchant' | 'organization' {
    return localStorage.getItem('spareView') as 'merchant' | 'organization';
  }

  /**
   * Return the menu Item for the view passed
   * @param view {'merchant'| 'organization' }
   * @returns { NbMenuItem[] }
   */
  getMenuItems(view?: 'merchant' | 'organization'): NbMenuItem[] {
    this._fillMenuItems();
    return this.MENU_ITEMS[view ? view : MenuItemsBuilder._activeView()];
  }

  /**
   * Add menu Items for merchant view and organization view
   * @private
   */
  private _fillMenuItems() {
    this.MENU_ITEMS = { merchant: [], organization: [] };
    this.MENU_ITEMS.merchant = [
      {
        title: 'Dashboard',
        icon: 'home-outline',
        link: '/pages/dashboard',
        home: true,
      },
      {
        title: 'Sales & Reports',
        icon: 'book-outline',
        hidden: !(
          this.adminRole &
          (merchantPermissions.SUPER_ADMIN +
            merchantPermissions.STORE_ADMIN +
            merchantPermissions.VIEW_SHIFTS +
            merchantPermissions.VIEW_BILLS +
            merchantPermissions.VIEW_PURCHASES +
            merchantPermissions.ALTER_PURCHASES +
            merchantPermissions.REVERSE_PURCHASES)
        ),
        children: [
          {
            title: 'Product Sales',
            icon: 'trending-up-outline',
            link: '/pages/sales-reports/product-sales',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_BILLS)
            ),
          },
          {
            title: 'Online Purchases',
            icon: 'shopping-bag-outline',
            link: '/pages/sales-reports/online-purchases',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_PURCHASES +
                merchantPermissions.ALTER_PURCHASES +
                merchantPermissions.REVERSE_PURCHASES)
            ),
          },
          {
            title: 'Vouchers Usage',
            icon: 'gift-outline',
            link: '/pages/inventory/voucher-reports',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_PURCHASES +
                merchantPermissions.VIEW_INVENTORY +
                merchantPermissions.ALTER_INVENTORY)
            ),
          },
          {
            title: 'Weekly Statements',
            icon: 'archive-outline',
            link: '/pages/sales-reports/weekly-statements',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_BILLS +
                merchantPermissions.RECORD_BILLS_PAID)
            ),
          },
          {
            title: 'Shifts',
            icon: 'clock-outline',
            link: '/pages/sales-reports/shifts',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_SHIFTS +
                merchantPermissions.VIEW_BILLS +
                merchantPermissions.RECORD_BILLS_PAID)
            ),
          },
          {
            title: 'Transactions',
            icon: 'swap-outline',
            link: '/pages/sales-reports/transactions',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_SHIFTS +
                merchantPermissions.VIEW_BILLS +
                merchantPermissions.RECORD_BILLS_PAID)
            ),
          },
          {
            title: 'Credit Card Recharges',
            icon: 'credit-card-outline',
            link: '/pages/sales-reports/credit-card-recharges',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_BILLS +
                merchantPermissions.RECORD_BILLS_PAID)
            ),
          },
          {
            title: 'Financial Reports',
            icon: 'bar-chart-outline',
            link: '/pages/sales-reports/financial-report',
            hidden: true
            // hidden: !(
            //   this.adminRole &
            //   (merchantPermissions.SUPER_ADMIN +
            //     merchantPermissions.STORE_ADMIN +
            //     merchantPermissions.VIEW_BILLS + 
            //     merchantPermissions.VIEW_PURCHASES)
            // ),
          },
        ],
      },
      {
        title: 'Inventory',
        icon: 'cube-outline',
        hidden: !(
          this.adminRole &
          (merchantPermissions.VIEW_INVENTORY +
            merchantPermissions.ALTER_INVENTORY +
            merchantPermissions.SUPER_ADMIN +
            merchantPermissions.STORE_ADMIN)
        ),
        children: [
          {
            title: 'Products',
            icon: 'copy-outline',
            link: '/pages/inventory/products',
          },
          {
            title: 'On-Campus Menus',
            icon: 'book-open-outline',
            link: '/pages/inventory/on-campus-menus',
          },
          {
            title: 'Online Menus',
            icon: 'book-open-outline',
            link: '/pages/inventory/online-menus',
          },
          {
            title: 'Bundles',
            icon: 'layers-outline',
            link: '/pages/inventory/bundles',
          },
          {
            title: 'Stock',
            icon: 'trending-up-outline',
            link: '/pages/inventory/stock/warehouses',
          },
        ],
      },
      {
        title: 'Settings',
        icon: 'settings-outline',
        hidden: !(
          this.adminRole &
          (merchantPermissions.SUPER_ADMIN +
            merchantPermissions.STORE_ADMIN +
            merchantPermissions.ALTER_BRANCHES +
            merchantPermissions.VIEW_BRANCHES +
            merchantPermissions.VIEW_CASHIERS +
            merchantPermissions.ALTER_CASHIERS +
            merchantPermissions.VIEW_MACHINES +
            merchantPermissions.BLOCK_MACHINES)
        ),
        children: [
          {
            title: 'Machines',
            icon: 'clipboard-outline',
            link: '/pages/settings/machines',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_MACHINES +
                merchantPermissions.BLOCK_MACHINES)
            ),
          },
          {
            title: 'Cashiers',
            icon: 'people-outline',
            link: '/pages/settings/cashiers',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_CASHIERS +
                merchantPermissions.ALTER_CASHIERS)
            ),
          },
          {
            title: 'Branches',
            icon: 'home-outline',
            link: '/pages/settings/branches',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.ALTER_BRANCHES +
                merchantPermissions.VIEW_BRANCHES)
            ),
          },
          {
            title: 'Discounts',
            icon: 'percent-outline',
            link: '/pages/inventory/coupons',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_INVENTORY +
                merchantPermissions.ALTER_INVENTORY)
            ),
          },
          {
            title: 'Vouchers (Beta)',
            icon: 'gift-outline',
            link: '/pages/inventory/vouchers',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.STORE_ADMIN +
                merchantPermissions.VIEW_INVENTORY +
                merchantPermissions.ALTER_INVENTORY)
            ),
          },
          {
            title: 'Webhooks',
            icon: 'code-outline',
            link: '/pages/settings/webhooks',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN + merchantPermissions.STORE_ADMIN)
            ),
          },
          {
            title: 'Integrations',
            icon: 'grid-outline',
            link: '/pages/settings/integrations',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN + merchantPermissions.STORE_ADMIN)
            ),
          },
        ],
      },
      {
        title: 'Super Admin',
        icon: 'lock-outline',
        hidden: !(
          this.adminRole &
          (merchantPermissions.SUPER_ADMIN +
            merchantPermissions.MANUAL_OVERRIDES +
            merchantPermissions.REGISTER_CHILDREN +
            merchantPermissions.RECORD_BILLS_PAID +
            merchantPermissions.ALTER_MERCHANTS +
            merchantPermissions.VIEW_MERCHANTS +
            merchantPermissions.ALTER_MACHINES_SUPER +
            merchantPermissions.ALTER_ORGANIZATION +
            merchantPermissions.VIEW_ORGANIZATIONS +
            merchantPermissions.SPARE_SUPPORT +
            merchantPermissions.SPARE_SUPER_SUPPORT)
        ),
        children: [
          {
            title: 'Merchant Setup',
            icon: 'shopping-bag-outline',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.VIEW_MERCHANTS +
                merchantPermissions.ALTER_MERCHANTS +
                merchantPermissions.ALTER_MACHINES_SUPER)
            ),
            children: [
              {
                title: 'Merchants',
                icon: 'shopping-bag-outline',
                link: '/pages/super/merchant-setup/merchants',
                hidden: !(
                  this.adminRole &
                  (merchantPermissions.SUPER_ADMIN +
                    merchantPermissions.VIEW_MERCHANTS +
                    merchantPermissions.ALTER_MERCHANTS)
                ),
              },
              {
                title: 'Categories',
                icon: 'list-outline',
                link: '/pages/super/merchant-setup/categories',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
              {
                title: 'Allergens',
                icon: 'activity-outline',
                link: '/pages/super/merchant-setup/allergens',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
              {
                title: 'Icons',
                icon: 'image-outline',
                link: '/pages/super/merchant-setup/icons',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
              {
                title: 'Machines',
                icon: 'clipboard-outline',
                link: '/pages/super/merchant-setup/machines',
                hidden: !(
                  this.adminRole &
                  (merchantPermissions.SUPER_ADMIN +
                    merchantPermissions.ALTER_MACHINES_SUPER +
                    merchantPermissions.VIEW_MACHINES)
                ),
              },
              {
                title: 'Tenders',
                icon: 'grid-outline',
                link: '/pages/super/merchant-setup/tenders',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
            ],
          },
          {
            title: 'Organization Setup',
            icon: 'people-outline',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.ALTER_ORGANIZATION +
                merchantPermissions.VIEW_ORGANIZATIONS)
            ),
            children: [
              {
                title: 'Org Groups',
                icon: 'globe-2-outline',
                link: '/pages/super/organization/organization-group',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
              {
                title: 'Organizations',
                icon: 'briefcase-outline',
                link: '/pages/super/organization/organizations',
                hidden: !(
                  this.adminRole &
                  (merchantPermissions.SUPER_ADMIN +
                    merchantPermissions.ALTER_ORGANIZATION +
                    merchantPermissions.VIEW_ORGANIZATIONS)
                ),
              },
              {
                title: 'Users',
                icon: 'person-outline',
                link: '/pages/super/organization/users',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
              {
                title: 'Notifications',
                icon: 'bell-outline',
                link: '/pages/super/organization/sendNotification',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
            ],
          },
          {
            title: 'Billing',
            icon: 'credit-card-outline',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN + merchantPermissions.RECORD_BILLS_PAID)
            ),
            children: [
              {
                title: 'Bills',
                icon: 'hash-outline',
                link: '/pages/super/billing/bills',
                hidden: !(
                  this.adminRole &
                  (merchantPermissions.SUPER_ADMIN + merchantPermissions.RECORD_BILLS_PAID)
                ),
              },
              {
                title: 'Offline Transactions',
                icon: 'swap-outline',
                link: '/pages/super/billing/offlineTransactions',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
              {
                title: 'Credit Card Recharges',
                icon: 'credit-card-outline',
                link: '/pages/super/billing/creditCardRecharges',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
              {
                title: 'On Campus Purchases',
                icon: 'credit-card-outline',
                link: '/pages/super/billing/on-campus-purchases',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
            ],
          },
          {
            title: 'System Settings',
            icon: 'settings-outline',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN + merchantPermissions.REGISTER_CHILDREN)
            ),
            children: [
              {
                title: 'Webhooks',
                icon: 'code-outline',
                link: '/pages/super/settings/webhooks',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
              {
                title: 'Integrations',
                icon: 'grid-outline',
                link: '/pages/super/settings/integrations',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
              {
                title: 'Partner Auth',
                icon: 'unlock-outline',
                link: '/pages/super/settings/partnerAuth',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
              },
              {
                title: 'General Setup',
                icon: 'settings-outline',
                children: [
                  {
                    title: 'Time Zones',
                    icon: 'clock-outline',
                    link: '/pages/super/settings/timeZones',
                    hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
                  },
                  {
                    title: 'Areas',
                    icon: 'map-outline',
                    link: '/pages/super/settings/areas',
                    hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
                  },
                  {
                    title: 'CSV Upload',
                    icon: 'code-outline',
                    link: '/pages/super/settings/csvUploader',
                    hidden: !(
                      this.adminRole &
                      (merchantPermissions.SUPER_ADMIN + merchantPermissions.REGISTER_CHILDREN)
                    ),
                  },
                  {
                    title: 'FAQs',
                    icon: 'question-mark-circle-outline',
                    link: '/pages/super/settings/faqs',
                    hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
                  },
                  {
                    title: 'Permissions',
                    icon: 'award-outline',
                    link: '/pages/super/settings/permissions',
                    hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
                  },
                  {
                    title: 'Country Codes',
                    icon: 'phone-outline',
                    link: '/pages/super/settings/country-codes',
                    hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
                  },
                ],
              },
              {
                title: 'Finance',
                icon: '',
                hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
                children: [
                  {
                    title: 'Currencies',
                    icon: '',
                    link: '/pages/super/settings/currencies',
                    hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
                  },
                  {
                    title: 'Gateway',
                    icon: '',
                    link: '/pages/super/settings/gateway',
                    hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
                  },
                  {
                    title: 'Payment Methods',
                    icon: '',
                    link: '/pages/super/settings/payment-methods',
                    hidden: !(this.adminRole & merchantPermissions.SUPER_ADMIN),
                  },
                ],
              },
            ],
          },
          {
            title: 'Support',
            icon: 'headphones-outline',
            hidden: !(
              this.adminRole &
              (merchantPermissions.SUPER_ADMIN +
                merchantPermissions.SPARE_SUPPORT +
                merchantPermissions.MANUAL_OVERRIDES)
            ),
            children: [
              {
                title: 'Support',
                icon: 'headphones-outline',
                link: '/pages/super/support/support',
                hidden: !(
                  this.adminRole &
                  (merchantPermissions.SUPER_ADMIN + merchantPermissions.SPARE_SUPPORT)
                ),
              },
              {
                title: 'Pins',
                icon: 'smartphone-outline',
                link: '/pages/super/support/pins',
                hidden: !(
                  this.adminRole &
                  (merchantPermissions.SUPER_ADMIN + merchantPermissions.MANUAL_OVERRIDES + merchantPermissions.SPARE_SUPER_SUPPORT)
                ),
              },
              {
                title: 'Phone Verifications',
                icon: 'smartphone-outline',
                link: '/pages/super/support/verifications',
                hidden: !(
                  this.adminRole &
                  (merchantPermissions.SUPER_ADMIN + merchantPermissions.MANUAL_OVERRIDES + merchantPermissions.SPARE_SUPER_SUPPORT)
                ),
              },
            ],
          },
        ],
      },
    ];

    this.MENU_ITEMS.organization = [
      {
        title: 'Organization',
        icon: 'briefcase-outline',
        link: '/pages/organization/organizations',
      },
      {
        title: 'All Users',
        icon: 'people-outline',
        link: '/pages/organization/all-users',
        hidden: !(
          this.orgRole &
          (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
            organizationPermissions.ORGANIZATION_ADMIN +
            organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE +
            organizationPermissions.ORGANIZATION_ALTER_ATTENDANCE +
            organizationPermissions.ORGANIZATION_VIEW_USERS +
            organizationPermissions.ORGANIZATION_ADMIN_ADD_CHILD)
        ),
      },
      {
        title: 'Attendance',
        icon: 'book-outline',
        hidden: !(
          this.orgRole &
          (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
            organizationPermissions.ORGANIZATION_ADMIN +
            organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE +
            organizationPermissions.ORGANIZATION_ALTER_ATTENDANCE)
        ),
        children: [
          {
            title: 'Attendance',
            icon: 'clipboard-outline',
            link: '/pages/organization/attendance/daily-attendance',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_CAMPUS_RECORD_ATTENDANCE +
                organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE +
                organizationPermissions.ORGANIZATION_ALTER_ATTENDANCE)
            ),
          },
          {
            title: 'Attendance Records',
            icon: 'clipboard-outline',
            link: '/pages/organization/attendance/all-attendance',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE +
                organizationPermissions.ORGANIZATION_ALTER_ATTENDANCE)
            ),
          },
          {
            title: 'Guardians',
            icon: 'shield-outline',
            link: '/pages/organization/attendance/guardians',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE +
                organizationPermissions.ORGANIZATION_ALTER_ATTENDANCE)
            ),
          },
          {
            title: 'Guardian Activity',
            icon: 'clipboard-outline',
            link: '/pages/organization/attendance/guardian-activity',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE +
                organizationPermissions.ORGANIZATION_ALTER_ATTENDANCE)
            ),
          },
          {
            title: 'Pickup',
            icon: 'navigation-2-outline',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE +
                organizationPermissions.ORGANIZATION_ALTER_ATTENDANCE +
                organizationPermissions.ORGANIZATION_VIEW_PICKUP_REQUESTS +
                organizationPermissions.ORGANIZATION_ALTER_PICKUP_REQUESTS
              )
            ),
            children: [
              {
                title: 'Requests',
                icon: 'file-outline',
                link: '/pages/organization/attendance/pickup-requests',
              },
              {
                title: 'Watchlist',
                icon: 'eye-outline',
                link: '/pages/organization/settings/watchlist',
                hidden: !(
                  this.orgRole &
                  (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                    organizationPermissions.ORGANIZATION_ADMIN)
                ),
              },
            ],
          },
        ],
      },
      {
        title: 'Billing',
        icon: 'credit-card-outline',
        hidden: !(
          this.orgRole &
          (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
            organizationPermissions.ORGANIZATION_ADMIN +
            organizationPermissions.ORGANIZATION_VIEW_BILLS +
            organizationPermissions.ORGANIZATION_RECORD_BILLS + 
            organizationPermissions.VIEW_OWN_VENDOR_SALES)
        ),
        children: [
          {
            title: 'Family Balances',
            icon: 'people-outline',
            link: '/pages/organization/billing/family-balance',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_BILLS +
                organizationPermissions.ORGANIZATION_RECORD_BILLS)
            )
          },
          {
            title: 'Cashless Transactions',
            icon: 'swap-outline',
            link: '/pages/organization/billing/cashless-transactions',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_BILLS +
                organizationPermissions.ORGANIZATION_RECORD_BILLS)
            )
          },
          // {
          //   title: 'Overview',
          //   icon: 'star-outline',
          //   link: '/pages/organization/billing/overview',
          //   hidden: !(localStorage.getItem('spareShowBilling') === 't'),
          // },
          {
            title: 'Weekly Statements',
            icon: 'file-text-outline',
            link: '/pages/organization/billing/weekly-statements',
            hidden: !(localStorage.getItem('spareShowBilling') === 't') || !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_BILLS +
                organizationPermissions.ORGANIZATION_RECORD_BILLS)
            ),
          },
          {
            title: 'Product Sales',
            icon: 'trending-up-outline',
            link: '/pages/organization/billing/product-sales',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_BILLS +
                organizationPermissions.ORGANIZATION_RECORD_BILLS + 
                organizationPermissions.VIEW_OWN_VENDOR_SALES
              )
            )
          },
          {
            title: 'Failed Transactions',
            icon: 'minus-circle-outline',
            link: '/pages/organization/billing/failed-transactions',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_BILLS +
                organizationPermissions.ORGANIZATION_RECORD_BILLS)
            ),
          },
          {
            title: 'Payment Requests',
            icon: 'clipboard-outline',
            link: "/pages/organization/billing/requests/payment",
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_BILLS +
                organizationPermissions.ORGANIZATION_RECORD_BILLS)
            )
          },
          {
            title: 'Cash Out Requests',
            icon: 'clipboard-outline',
            link: "/pages/organization/billing/requests/cashOut",
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_BILLS +
                organizationPermissions.ORGANIZATION_RECORD_BILLS)
            )
          },
        ],
      },
      {
        title: 'Student Fees',
        icon: 'person-done-outline',
        hidden: !(
          this.orgRole &
          (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
            organizationPermissions.ORGANIZATION_ADMIN +
            organizationPermissions.VIEW_SCHOOL_FEES +
            organizationPermissions.ALTER_SCHOOL_FEES)
        ),
        children: [
          {
            title: 'Fee Requests',
            icon: 'file-text-outline',
            link: '/pages/organization/student-fees/fee-requests',
          },
          // {
          //   title: 'Student Fees',
          //   icon: 'swap-outline',
          //   link: '/pages/organization/student-fees/fees-payments',
          // },
          {
            title: 'Payment Transactions',
            icon: 'swap-outline',
            link: '/pages/organization/student-fees/payment-transactions',
          },
        ],
      },
      {
        title: 'Parent Communication',
        icon: 'message-square-outline',
        hidden: !(
          this.orgRole &
          (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
            organizationPermissions.ORGANIZATION_ADMIN +
            organizationPermissions.ORGANIZATION_VIEW_NEWS_FEED +
            organizationPermissions.ORGANIZATION_ALTER_NEWS_FEED)
        ),
        children: [
          {
            title: 'News Feed',
            icon: 'paper-plane-outline',
            link: '/pages/organization/parent-communication/news-feed',
          },
          {
            title: 'Information Center',
            icon: 'attach-2-outline',
            link: '/pages/organization/parent-communication/important-links',
          },
        ],
      },
      {
        title: 'Bus System',
        icon: 'car-outline',
        hidden: !(
          this.orgRole &
          (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
            organizationPermissions.ORGANIZATION_ADMIN +
            organizationPermissions.ORGANIZATION_VIEW_BUS_SYSTEM +
            organizationPermissions.ORGANIZATION_ALTER_BUS_SYSTEM)
        ),
        children: [
          {
            title: 'Student Schedules',
            icon: 'calendar-outline',
            link: '/pages/bus-system/students-schedule',
          },
          {
            title: 'Student Excuses',
            icon: 'alert-circle-outline',
            link: '/pages/bus-system/bus-excuses',
          },
          {
            title: 'Bus Schedules',
            icon: 'clock-outline',
            link: '/pages/bus-system/bus-schedule',
          },
          {
            title: 'Setup',
            icon: 'settings-outline',
            children: [
              {
                title: 'Bus & Route Setup',
                icon: 'map-outline',
                link: '/pages/bus-system/bus-routes',
              },
              {
                title: 'Bus Monitors',
                icon: 'people-outline',
                link: '/pages/bus-system/bus-monitors',
              },
            ],
          },
        ],
      },
      {
        title: 'Settings',
        icon: 'settings-outline',
        hidden: !(
          this.orgRole &
          (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
            organizationPermissions.ORGANIZATION_ADMIN +
            organizationPermissions.ORGANIZATION_ALTER_OVERDRAFT +
            organizationPermissions.ORGANIZATION_ALTER_ATTENDANCE +
            organizationPermissions.ORGANIZATION_ADMIN_ADD_CHILD +
            organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE +
            organizationPermissions.ORGANIZATION_DEACTIVATE_CHILD +
            organizationPermissions.ORGANIZATION_ALTER_GATES + 
            organizationPermissions.ORGANIZATION_MANAGE_INTEGRATIONS)
        ),
        children: [
          {
            title: 'Overdraft Rules',
            icon: 'activity-outline',
            link: '/pages/organization/settings/overdraft-rules',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ALTER_OVERDRAFT)
            ),
          },
          {
            title: 'Override Cards',
            icon: 'credit-card-outline',
            link: '/pages/organization/settings/override-cards',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN)
            ),
          },
          {
            title: 'Purchase View Rules',
            icon: 'eye-off-outline',
            link: '/pages/organization/settings/purchase-view-rules',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN)
            ),
          },
          {
            title: 'Product Restrictions',
            icon: 'alert-triangle-outline',
            link: '/pages/organization/settings/product-restrictions',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN)
            ),
          },
          {
            title: 'Deactivated Users',
            icon: 'close-circle-outline',
            link: '/pages/organization/settings/deactivated-users',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_DEACTIVATE_CHILD +
                organizationPermissions.ORGANIZATION_ADMIN)
            ),
          },
          {
            title: 'Digital IDs',
            icon: 'keypad-outline',
            link: '/pages/organization/settings/digital-ids',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_VIEW_DIGITAL_IDS +
                organizationPermissions.ORGANIZATION_ALTER_DIGITAL_IDS +
                organizationPermissions.ORGANIZATION_ALTER_ATTENDANCE +
                organizationPermissions.ORGANIZATION_ADMIN_ADD_CHILD +
                organizationPermissions.ORGANIZATION_VIEW_ATTENDANCE)
            ),
          },
          {
            title: 'School Gates',
            icon: 'arrow-circle-right-outline',
            link: '/pages/organization/settings/gates',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN +
                organizationPermissions.ORGANIZATION_ALTER_GATES)
            ),
          },
          {
            title: 'Grades',
            icon: 'bar-chart-2-outline',
            link: '/pages/organization/settings/grades',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN)
            ),
          },
          {
            title: 'Webhooks',
            icon: 'code-outline',
            link: '/pages/settings/webhooks',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN + 
                organizationPermissions.ORGANIZATION_MANAGE_INTEGRATIONS)
            ),
          },
          {
            title: 'Integrations',
            icon: 'grid-outline',
            link: '/pages/settings/integrations',
            hidden: !(
              this.orgRole &
              (organizationPermissions.ORGANIZATION_SUPER_ADMIN +
                organizationPermissions.ORGANIZATION_ADMIN + 
                organizationPermissions.ORGANIZATION_MANAGE_INTEGRATIONS)
            ),
          },
        ],
      },
    ];
  }
}
