import * as moment from 'moment';

export default class Bundle {
  bundleID: number;
  bundleName: string;
  bundleReference: string;
  merchantID: number;
  minBundleSize: number;
  allowReplacements: boolean;
  imageURL: string;
  description: string;
  totalBranches: number;
  minPrice: number;
  maxPrice: number;
  currencyCode: string;
  categoryID: number;

  constructor(raw: any) {
    delete raw.deletedAt;
    Object.assign(this, raw);
    this.allowReplacements = raw.allowReplacement === 1;
  }
}
