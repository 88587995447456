import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteService } from '../../route.service';
import { ToastService } from '../../toast.service';
import { environment } from '../../../environments/environment';
import { BusMonitors } from './bus-monitors/bus-monitors.model';
import * as moment from 'moment';
import { Moment } from 'moment';
import { catchError, map } from 'rxjs/operators';
import { StudentOnBoard } from './models/student-on-board.model';
import { Trip } from './models/trip.model';
import { TripExcuse } from './models/trip-excuse.model';
import { BusStudent } from './models/bus-student.model';
import { BusRoute } from './models/bus-route.model';
import { Bus, RouteInfo } from './models/bus.model';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { DateRange } from '../../ui/spare-components/smart-datepicker/date-range.model';
import { BusScheduleTrip, TripStatus } from './models/bus-schedule-trip';
import { BoardListModifyItem } from './models/board-list-modify-item.model';
import { BoardList, BoardListRecord } from './models/board-list';
import { DateUtils } from '../../utils/date-utils';
import { TimeZoneService } from '../time-zone/time-zone.service';
import { ChildNote } from './models/child-note.model';
import { BusNotification } from './models/bus-notification.model';

@Injectable()
export class BusSystemService {
  private selectedTrip: Trip;

  selectedMonitor: BusMonitors;
  selectedIndex: number;

  buses;
  baseUrl: string = environment.baseUrl;

  orgId = localStorage.getItem('spareSelectedOrganization');
  monitorsIdMap;

  monitors;
  monitorsUsernames;
  dateUtils: DateUtils;

  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private toastService: ToastService,
    timeZoneService: TimeZoneService
  ) {
    this.dateUtils = new DateUtils(timeZoneService.getCachedTimeZoneDBName());
  }

  /**
   * Gets all the buses with route info from the server.
   * @returns {Promise<Bus[]>}
   */
  getBuses(): Promise<Bus[]> {
    const url: string = this.baseUrl + '/bus-system/bus/admin/routes?organizationID=' + this.orgId;
    return this.httpClient
      .get<any>(url)
      .pipe(
        catchError(this._errorHandler),
        map((res) => this._parseBuses(res))
      )
      .toPromise();
  }

  addBus(plateNo, number, year, model) {
    const bodyContent = {
      organizationID: this.orgId,
      plateNo,
      number,
      year,
      model,
    };
    const body = [];
    body.push(bodyContent);
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/bus-system/bus/admin?organizationID=' + this.orgId;
      this.httpClient.post<any>(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Bus Added');
        },
        (error) => {
          this.toastService.show('danger', 'Error', error.error.message);
          reject(error);
        }
      );
    });
  }

  editBus(busID, plateNo, number, year, model) {
    const body = {
      organizationID: this.orgId,
      plateNo,
      number,
      year,
      model,
    };
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + `/bus-system/bus/admin/${busID}`;
      this.httpClient.put(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Bus updated');
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.error.message);
        }
      );
    });
  }

  getRouteByID(routeID): Promise<BusRoute> {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl + `/bus-system/route/admin/${routeID}?organizationID=${this.orgId}`;
      this.httpClient.get<any>(url).subscribe(
        (res: any) => {
          resolve(this._pareRoutes(res)[0]);
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.error.message);
        }
      );
    });
  }

  addRoute(areaID, startTime, busID, direction, code?, distance?, parentExcuseCutOffMinutes?) {
    const body = {
      organizationID: +this.orgId,
      areaID,
      startTime,
      busID,
      direction,
      code,
      distance,
      parentExcuseCutOffMinutes,
    };
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/bus-system/route/admin?organizationID=' + this.orgId;
      this.httpClient.post<any>(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Bus Route Added');
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.error.message);
        }
      );
    });
  }

  editRoute(routeID: number, parentExcuseCutOffMinutes: number): Promise<any> {
    const body = {
      organizationID: this.orgId,
      parentExcuseCutOffMinutes,
    };
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + `/bus-system/route/admin/${routeID}`;
      this.httpClient.put(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Route updated');
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.error.message);
        }
      );
    });
  }

  get getSelectedTrip(): Trip {
    return this.selectedTrip;
  }

  set setSelectedTrip(trip: Trip) {
    this.selectedTrip = trip;
  }

  addMonitor(
    firstName: string,
    lastName: string,
    userName: string,
    password: string,
    confirmPassword: string,
    email: string,
    phoneNumber: string
  ) {
    const bodyContent = {
      firstName,
      lastName,
      username: userName,
      phoneNumber,
      password,
      email,
    };
    const body = [];
    body.push(bodyContent);
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl + '/bus-system/bus-monitor/admin?organizationID=' + encodeURI(this.orgId);
      this.httpClient.post<any>(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Bus Monitor Added');
        },
        (error) => {
          if (error.status === 409) {
            return error.error.forEach((e) => this.toastService.show('danger', 'Error', e.message));
          }
          this.toastService.show('danger', 'Error', error.error.message);
          reject(error);
        }
      );
    });
  }

  formatDate(date) {
    if (date) {
      return moment(date).format('ddd, MMM DD hh:mm:ss');
    } else {
      return 'Not Completed';
    }
  }

  /**
   * Get all active trips form the server
   * @returns {Promise<Trip[]>}
   */
  getAllActiveTrips(): Promise<Trip[]> {
    const url = `${this.baseUrl}/bus-system/trip/admin/active-trips/?organizationID=${this.orgId}`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError(this._errorHandler),
        map((res: any) => this._parseTrips(res))
      )
      .toPromise();
  }

  /**
   * Get all past trips form the server
   * @returns {Promise<Trip[]>}
   */
  getAllPastTrips(): Promise<Trip[]> {
    const url = `${this.baseUrl}/bus-system/trip/admin/past-trips/?organizationID=${this.orgId}`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError(this._errorHandler),
        map((res: any) => this._parseTrips(res))
      )
      .toPromise();
  }

  updateMonitorPassword(busMonitorID, newPassword) {
    const body = {
      organizationID: this.orgId,
      busMonitorID,
      newPassword,
    };
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + '/bus-system/bus-monitor/admin/update-password';
      this.httpClient.put(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Bus monitor password updated');
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.error.message);
        }
      );
    });
  }

  getMonitors() {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl + '/bus-system/bus-monitor/admin?organizationID=' + this.orgId;
      const busMonitors: BusMonitors[] = [];
      this.httpClient.get<any>(url).subscribe(
        (res: any) => {
          for (const item of res) {
            const temp: BusMonitors = new BusMonitors(
              item.busMonitorID,
              (item.name = item.firstName + ' ' + item.lastName),
              item.username,
              item.phoneNumber,
              item.email,
              item.firstName,
              item.lastName
            );
            busMonitors.push(temp);
          }
          this.monitors = busMonitors;
          resolve(busMonitors);
        },
        (error) => {
          this.routeService.checkErr(error).then((err: any) => {
            reject(err);
            this.toastService.show('danger', 'Error', err.err);
          });
        }
      );
    });
  }

  updateMonitor(
    busMonitorID,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  ) {
    const body = {
      organizationID: this.orgId,
      firstName,
      lastName,
      email,
      phoneNumber,
    };
    return new Promise((resolve, reject) => {
      const url: string = this.baseUrl + `/bus-system/bus-monitor/admin/${busMonitorID}`;
      this.httpClient.put(url, body).subscribe(
        () => {
          resolve('success');
          this.toastService.show('success', 'Success', 'Bus monitor updated');
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.error.message);
        }
      );
    });
  }

  /**
   * Getting all students on board in a trip
   * @param routeID
   * @param tripID
   */
  getBoardListOfTrip(routeID: number, tripID: number): Promise<StudentOnBoard[]> {
    const url =
      // eslint-disable-next-line max-len
      `${this.baseUrl}/bus-system/child-board-list-record/admin/${routeID}/${tripID}/?organizationID=${this.orgId}`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError(this._errorHandler),
        map((res: any) => this._parseTripBoardList(res))
      )
      .toPromise();
  }

  getBoardList(routeID): Promise<any> {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl +
        `/bus-system/child-board-list/admin/route/${routeID}/?organizationID=${this.orgId}`;
      this.httpClient.get<any>(url).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.error.message);
        }
      );
    });
  }

  deleteBus(busID) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl + `/bus-system/bus/admin/${busID}?organizationID=${this.orgId}`;
      this.httpClient.delete<any>(url).subscribe(
        (res: any) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Bus Deleted Successfully');
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.error.message);
        }
      );
    });
  }

  modifyChildrenBoardList(
    addList: BoardListModifyItem[],
    removeList: BoardListModifyItem[]
  ): Promise<any> {
    const organizationID = this.orgId;
    const URL = `${this.baseUrl}/bus-system/child-board-list/admin?organizationID=${organizationID}`;

    return this.httpClient
      .post(URL, { removeList, addList })
      .pipe(catchError(this._modifyBoardListErrorHandler))
      .toPromise();
  }

  deleteRoute(routeID) {
    return new Promise((resolve, reject) => {
      const url: string =
        this.baseUrl + `/bus-system/route/admin/${routeID}?organizationID=${this.orgId}`;
      this.httpClient.delete<any>(url).subscribe(
        (res: any) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Route Deleted Successfully');
        },
        (error) => {
          reject(error);
          this.toastService.show('danger', 'Error', error.error.message);
        }
      );
    });
  }

  /**
   * Get all one time excuses from the server
   * @return {TripExcuse []}
   */
  getOneTimeExcuses(dateRange: DateRange): Promise<TripExcuse[]> {
    let dateFrom = null,
      dateTo = null;
    if (dateRange.startDate && dateRange.endDate) {
      dateFrom = moment(dateRange.startDate).format('YYYY-MM-DD HH:mm:ss');
      dateTo = moment(dateRange.endDate).format('YYYY-MM-DD HH:mm:ss');
    }
    let url =
      // eslint-disable-next-line max-len
      `${this.baseUrl}/bus-system/one-time-excuse/admin/?organizationID=${this.orgId}`;
    if (dateFrom && dateTo) url += `&from=${dateFrom}&to=${dateTo}`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError(this._errorHandler),
        map((res: any) => this._parseTripExcuses(res))
      )
      .toPromise();
  }

  /**
   * Get all one permanent excuses from the server
   * @return {TripExcuse []}
   */
  getPermanentExcuses(): Promise<TripExcuse[]> {
    const url =
      // eslint-disable-next-line max-len
      `${this.baseUrl}/bus-system/permanent-excuse/admin/?organizationID=${this.orgId}`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError(this._errorHandler),
        map((res: any) => this._parseTripExcuses(res))
      )
      .toPromise();
  }

  /**
   * Get all (one time/permanent) excuses from the server
   * @return {TripExcuse []}
   */
  getAllExcuses(date: moment.Moment): Promise<TripExcuse[]> {
    const url =
      // eslint-disable-next-line max-len
      `${this.baseUrl}/bus-system/generic-excuse/admin/?organizationID=${
        this.orgId
      }&date=${date.format('YYYY-MM-DD')}`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError(this._errorHandler),
        map((res: any) => this._parseTripExcuses(res.excuses || []))
      )
      .toPromise();
  }

  /**
   * Get all students that registered on buses from server
   * @returns {BusStudent[]}
   */
  getAllStudentsInBuses(): Promise<BusStudent[]> {
    const url =
      // eslint-disable-next-line max-len
      `${this.baseUrl}/bus-system/child-board-list/admin/all-children?organizationID=${this.orgId}`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError(this._errorHandler),
        map((res: any) => res as BusStudent[])
      )
      .toPromise();
  }

  /**
   * Get all route for a student by childID
   * @param childID {number}
   */
  getCurrentRoutesByChildID(childID: number): Promise<BusRoute[]> {
    const url =
      // eslint-disable-next-line max-len
      `${this.baseUrl}/bus-system/child-board-list/admin/current/${childID}/?organizationID=${this.orgId}`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError(this._errorHandler),
        map((res: any) => this._pareRoutes(res))
      )
      .toPromise();
  }

  /**
   * Get all route by organizationID.
   * @params direction {1|0} an optional filer with direction of the routes
   * @returns {Promise<BusRoute[]>}
   */
  getAllRoutesByOrgID(direction?: 1 | 0): Promise<BusRoute[]> {
    let url = `${this.baseUrl}/bus-system/route/admin/?organizationID=${this.orgId}`;
    if (typeof direction === 'number') url = url + '&direction=' + direction;
    return this.httpClient
      .get(url)
      .pipe(
        catchError(this._errorHandler),
        map((res: any) => this._pareRoutes(res))
      )
      .toPromise();
  }

  /**
   *Adds ONE one time excuse
   * @param body
   */
  addNewOneTimeExcuse(body: {
    childID: number;
    childBoardListExcuseID: number;
    routeID: number;
    date: Moment;
    description: string;
  }): Promise<any> {
    const url = `${this.baseUrl}/bus-system/one-time-excuse/admin/?organizationID=${this.orgId}`;
    const parsedDate: string = body.date.format('YYYY-MM-DD');
    const bodyClone = JSON.parse(JSON.stringify(body));
    bodyClone.date = parsedDate;
    return this.httpClient
      .post(url, [bodyClone])
      .pipe(
        catchError((e) => {
          this._excuseErrorHandler(e);
          return this._errorHandler(e);
        })
      )
      .toPromise();
  }

  /**
   *Adds ONE or more permanent excuse
   * @param body
   */
  addNewPermanentExcuse(body): Promise<any> {
    const url = `${this.baseUrl}/bus-system/permanent-excuse/admin/?organizationID=${this.orgId}`;
    return this.httpClient
      .post(url, body)
      .pipe(
        catchError((e) => {
          this._excuseErrorHandler(e);
          return this._errorHandler(e);
        })
      )
      .toPromise();
  }

  /**
   *Adds BULK one time excuses
   * @param body {Array}
   */
  bulkAddOneTimeExcuses(body): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/bus-system/one-time-excuse/admin/?organizationID=${this.orgId}`;
      return this.httpClient.post(url, body).subscribe(
        (res: any) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'One Time Excuses Added');
        },
        (error) => {
          reject(error);
          this.toastService.show(
            'danger',
            'Error',
            'An Error Occurred. An Excel File Will Be Downloaded With Details.'
          );
        }
      );
    });
  }

  /**
   *Adds BULK permanent excuses
   * @param body {Array}
   */
  bulkAddPermanentExcuses(body): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseUrl}/bus-system/permanent-excuse/admin/?organizationID=${this.orgId}`;
      return this.httpClient.post(url, body).subscribe(
        (res: any) => {
          resolve(res);
          this.toastService.show('success', 'Success', 'Permanent Excuses Added');
        },
        (error) => {
          reject(error);
          this.toastService.show(
            'danger',
            'Error',
            'An Error Occurred. An Excel File Will Be Downloaded With Details.'
          );
        }
      );
    });
  }

  /**
   * Get trip information by trip ID
   * @param id {number} - the trip ID
   * @returns {Promise<Trip>}
   */
  getTripByID(id: number): Promise<Trip> {
    const url = `${this.baseUrl}/bus-system/trip/admin/${id}/?organizationID=${this.orgId}`;
    return this.httpClient
      .get(url)
      .pipe(
        catchError(this._errorHandler),
        map((res: any) => this._parseTrips([res])[0])
      )
      .toPromise();
  }

  /**
   * Delete one time excuse by calling delete end point using excuse ID.
   * @param excuseID {number} - The ID of excuse to delete
   * @returns {Promise<{ message: string }>}
   */
  deleteOneTimeExcuse(excuseID: number): Promise<any> {
    const url = `${this.baseUrl}/bus-system/one-time-excuse/admin/${excuseID}/?organizationID=${this.orgId}`;
    return this.httpClient.delete(url).pipe(catchError(this._errorHandler)).toPromise();
  }
  /**
   * Delete permanent excuse by calling delete end point using excuse ID.
   * @param excuseID {number} - The ID of excuse to delete
   * @returns {Promise<{ message: string }>}
   */
  deletePermanentTimeExcuse(excuseID: number): Promise<any> {
    const url = `${this.baseUrl}/bus-system/permanent-excuse/admin/${excuseID}/?organizationID=${this.orgId}`;
    return this.httpClient
      .delete(url)
      .pipe(
        catchError((e) => {
          this._excuseErrorHandler(e);
          return this._errorHandler(e);
        })
      )
      .toPromise();
  }

  /**
   * Getting all students with their standard routes
   *
   */
  getStudentsWithStandardRoutes(): Promise<any> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      const url = `${this.baseUrl}/bus-system/child-board-list/admin/all-children-standard-routes/?organizationID=${this.orgId}`;
      return this.httpClient.get(url).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Getting all excuses by childID
   *
   */
  getExcusesByChildID(childID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      const url = `${this.baseUrl}/bus-system/generic-excuse/admin/upcoming/${childID}/?organizationID=${this.orgId}`;
      return this.httpClient.get(url).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getBusSchedule(orgID: number, date: string): Promise<BusScheduleTrip[][]> {
    const url = `${this.baseUrl}/bus-system/bus-schedule/admin/${orgID}?date=${date}&organizationID=${orgID}`;

    return this.httpClient
      .get(url)
      .pipe(
        map(this._parseBusSchedule(date)),
        catchError((e) => {
          this._excuseErrorHandler(e);
          return this._errorHandler(e);
        })
      )
      .toPromise();
  }

  getStudentsOnRouteWithStatus(trip: BusScheduleTrip): StudentOnBoard[] {
    const boardList = trip.boardLists;
    const boardListRecord = trip.boardListsRecords;

    return boardList ? boardList.map(boardListMapper(boardListRecord)) : [];

    function boardListMapper(
      boardListRecordList: BoardListRecord[]
    ): (boardListItem: BoardList) => StudentOnBoard {
      return (boardListItem: BoardList): StudentOnBoard => {
        const onBoardStudent: StudentOnBoard = {
          childBoardListID: boardListItem.childBoardListID,
          childGrade: boardListItem.grade,
          externalStudentID: boardListItem.externalStudentID,
          childID: boardListItem.childID,
          fullName: boardListItem.childName,
          excuseStatus: boardListItem.excuseStatus,
          excuseReason: boardListItem.description,
          isExcused: boardListItem.excuseStatus === 'EXCUSED',
          isAdded: boardListItem.excuseStatus === 'ADDED',
          notificationCount: boardListItem.notificationCount,
          notificationTimestamps: boardListItem.notificationTimestamps,
          childNotes: boardListItem.childNotes,
        };

        const studentBoardListRecord = boardListRecordList
          ? boardListRecordList.find((record) => record.childID === boardListItem.childID)
          : null;

        if (studentBoardListRecord) {
          /**
           *
           *  onBoard | gotOffAt |  status
           * ----------------------------------
           *    0     |    0     |  ABSENT
           * ----------------------------------
           *    0     |    1     |  NOT_A_CASE
           * ----------------------------------
           *    1     |    0     |  ON_BUS
           * ----------------------------------
           *    1     |    1     |  DROPPED_OFF
           *
           */

          if (studentBoardListRecord.onBoard && studentBoardListRecord.gotOffAt) {
            onBoardStudent.isDroppedOff = true;
            onBoardStudent.gotOffAt = moment(studentBoardListRecord.gotOffAt);
            onBoardStudent.pickedUpAt = moment(studentBoardListRecord.addedOn);
            onBoardStudent.status = 'dropped off';
          } else if (studentBoardListRecord.onBoard && !studentBoardListRecord.gotOffAt) {
            onBoardStudent.isPresent = true;
            onBoardStudent.pickedUpAt = moment(studentBoardListRecord.addedOn);
            onBoardStudent.status = 'on bus';
          } else if (!studentBoardListRecord.onBoard && !studentBoardListRecord.gotOffAt) {
            onBoardStudent.isAbsent = true;
            onBoardStudent.status = 'absent';
            onBoardStudent.absentReason = studentBoardListRecord.noShowReason;
          }
        }

        return onBoardStudent;
      };
    }
  }

  endTripByID(tripID: number): Promise<any> {
    const organizationID = this.orgId;
    const url = `${this.baseUrl}/bus-system/trip/admin/end?organizationID=${organizationID}`;
    return this.httpClient.put(url, { tripID }).toPromise();
  }

  getAllChildNotes(childID: number): Promise<ChildNote[]> {
    const url = `${this.baseUrl}/family-account/child-note/${childID}/?organizationID=${this.orgId}`;

    return this.httpClient
      .get(url)
      .pipe(catchError(this._errorHandler), map(this.parseChildNotes))
      .toPromise();
  }

  addNewChildNote(childNote: Partial<ChildNote>): Promise<any> {
    const url = `${this.baseUrl}/family-account/child-note?organizationID=${this.orgId}`;

    return this.httpClient
      .post(url, { childNotes: [childNote] })
      .toPromise()
      .catch((err) => {
        this._errorHandler(err);
        throw err;
      });
  }

  deleteChildNote(noteID: number): Promise<any> {
    const url = `${this.baseUrl}/family-account/child-note/${noteID}?organizationID=${this.orgId}`;

    return this.httpClient
      .delete(url)
      .toPromise()
      .catch((err) => {
        this._errorHandler(err);
      });
  }

  getBusNotifications(childBoardListID: number, date: string): Promise<BusNotification[]> {
    const url = `${this.baseUrl}/bus-system/child-board-list/notifications/${childBoardListID}`;

    const source$ = this.httpClient
      .get<BusNotification[]>(url, { params: { organizationID: this.orgId, date } })
      .pipe(map((ns) => ns.map((n) => new BusNotification(n))));

    return lastValueFrom(source$);
  }

  /**
   * Parse data comes from the schedule API response to format dates.
   * @param schedule - schedule API response body to parse.
   * @returns {BusScheduleTrip [][]}
   */
  private _parseBusSchedule(filterData: string): (schedules: any) => BusScheduleTrip[][] {
    return (schedules: any): BusScheduleTrip[][] => {
      const schedulesPerBus: { [busID: string]: BusScheduleTrip[] } = {};

      schedules.forEach((schedule) => {
        const _schedule: BusScheduleTrip = { ...schedule };

        _schedule.startedAt = this.dateUtils.formatStringUTCDateToOrganizationTimeZone(
          schedule.tripStartedAt
        );

        _schedule.endedAt = this.dateUtils.formatStringUTCDateToOrganizationTimeZone(
          schedule.tripEndedAt
        );
        const dateTimeStartTime = `${filterData} ${schedule.routeStartTime}`;

        _schedule.scheduledStartTime =
          this.dateUtils.formatStringDateInOrganizationTimezone(dateTimeStartTime);

        _schedule.status = getTripStatus(_schedule);

        if (_schedule?.boardLists) {
          for (let student of _schedule?.boardLists) {
            student.notificationTimestamps = student.notificationTimestamps
              ?.split(',')
              .map((t) => moment.utc(t, ['hh:mm A']).local().format('hh:mm A'))
              .join(', ');
          }
        }

        _schedule.boardList = this.getStudentsOnRouteWithStatus(_schedule);

        if (schedulesPerBus[schedule.busID]) {
          schedulesPerBus[schedule.busID].push(_schedule);
        } else {
          schedulesPerBus[schedule.busID] = [_schedule];
        }
      });

      return Object.values(schedulesPerBus);

      function getTripStatus(__schedule__: BusScheduleTrip): TripStatus {
        const isFutureTrip = moment().isBefore(__schedule__.scheduledStartTime);

        // The trip is not started, and it's scheduled in the future.
        if (isFutureTrip && !__schedule__.tripID) {
          return 'FUTURE_SCHEDULED';
        } else if (__schedule__.tripID) {
          if (__schedule__.startedAt && __schedule__.endedAt) {
            return 'TRIP_ENDED';
          } else if (__schedule__.startedAt && !__schedule__.endedAt) {
            return 'TRIP_STARTED';
          } else {
            return 'TRIP_NOT_STARTED';
          }
        } else {
          return 'TRIP_NOT_STARTED';
        }
      }
    };
  }
  /**
   * Parsing server response of trips calls into a trips array.
   * @param trips {any[]} - response of trips end points.
   * @private
   * @returns {Trip[]}
   */
  private _parseTrips(trips: any[]): Trip[] {
    return trips.map((t: any) => {
      t.tripStartedAt = t.tripStartedAt ? moment.utc(t.tripStartedAt).local() : null;
      t.tripEndedAt = t.tripEndedAt ? moment.utc(t.tripEndedAt).local() : null;
      t.from = t.direction === 0 ? 'school' : t.destination;
      t.to = t.direction === 1 ? 'school' : t.destination;
      t.routeStartTime = moment.utc(t.routeStartTime, 'HH:mm:ss').format('h:mm A');
      return t as Trip;
    });
  }

  /**
   * Parsing server response of boardList call into a StudentOnBoard array.
   * @param list {any[]} - response of boardList end point call.
   * @private
   * @returns {StudentOnBoard[]}
   */
  private _parseTripBoardList(list: any[]): StudentOnBoard[] {
    return list.map((item: any) => {
      if (item.isExcused) item.status = 'excused';
      else if (item.isDroppedOff) item.status = 'dropped off';
      else if (item.isAbsent) item.status = 'absent';
      else if (item.isPresent) item.status = 'on bus';
      else item.status = '';

      item.notificationTimestamps = item.notificationTimestamps
        ?.split(',')
        .map((time: string) => moment.utc(time, ['hh:mm A']).local().format('hh:mm A'))
        .join(', ');
      return item as StudentOnBoard;
    });
  }

  /**
   * Get the response of the excuses routes and parse it to reusable in the components
   * @param list {ExcusesResponse []} - the response body expected from the server.
   * @private
   * @returns {TripExcuse []}
   */
  private _parseTripExcuses(list: ExcusesResponse[]): TripExcuse[] {
    return list.map((excuse) => ({
      childName: excuse.childName,
      date: excuse.date ? moment.utc(excuse.date).format('D/M/YYYY') : '',
      every: !Number.isNaN(+excuse.weekday) ? moment().day(excuse.weekday).format('dddd') : '',
      description: excuse.description,
      externalStudentID: excuse.externalStudentID,
      grade: excuse.grade,
      className: excuse.className,
      oneTimeExcuseID: excuse.oneTimeExcuseID,
      permanentExcuseID: excuse.permanentExcuseID,
      originalBusNumber: excuse.originalBusNumber,
      originalRoute: _getRouteString(
        !!excuse.originalDirection,
        excuse.originalAreaName,
        excuse.originalStartTime
      ),
      newRoute: _getRouteString(!!excuse.newDirection, excuse.newAreaName, excuse.newStartTime),
    }));

    /**
     * This function route params to get a string that describe the route
     * @param toSchool {boolean}
     * @param areaName
     * @param startTime
     * @returns {string}
     */
    function _getRouteString(toSchool: boolean, areaName: string, startTime: string): string {
      let routeString = '-';
      if (areaName && startTime) {
        if (toSchool) {
          routeString = `${areaName} to School, ${moment
            .utc(startTime, 'HH:mm:ss')
            .format('h:mmA')}`;
        } else {
          routeString = `School to ${areaName}, ${moment
            .utc(startTime, 'HH:mm:ss')
            .format('h:mmA')}`;
        }
      }
      return routeString;
    }
  }

  /**
   * Handle error of modify children board list API if passed.
   * @param error
   * @returns {Observable<never>}
   */
  private _modifyBoardListErrorHandler = (error): Observable<never> => {
    if (error.error.childrenBoardListsIssues) {
      for (const err of error.error.childrenBoardListsIssues) {
        const text = `${err.fullName} could not be added to the board list. Reason: ${err.reason}`;
        this.toastService.show('danger', 'Error', text, 1000000);
      }
      return throwError(error);
    } else {
      return this._errorHandler(error);
    }
  };
  /**
   * Parse bus route server response
   * @param routes {any[]} - the server response body (should be found in APIs docs)
   * @private
   * @return {BusRoute[]}
   */
  private _pareRoutes(routes: any[]): BusRoute[] {
    return routes.map((route: any) => {
      const _route: BusRoute = { ...route };
      const routeMoment = moment(route.startTime, 'HH:mm:ss');
      _route.from = _getRouteDescription(route.direction, route.areaName).from;
      _route.to = _getRouteDescription(route.direction, route.areaName).to;
      _route.description = _getRouteDescription(route.direction, route.areaName).description;
      // _route.routeStartTime = this.dateUtils
      //   .formatMomentToOrganizationTimeZone(routeMoment)
      //   .format('h:mm A');
      _route.routeStartTime = routeMoment.format('h:mm a');
      return _route;
    });

    /**
     * This function route params to get a string that describe the route
     * @param toSchool {boolean}
     * @param areaName {string}
     * @returns { description: string, from: string, to: string }
     */
    function _getRouteDescription(
      toSchool: boolean,
      areaName: string
    ): { description: string; from: string; to: string } {
      return {
        description: toSchool ? `${areaName} to School` : `School to ${areaName}`,
        from: toSchool ? `${areaName}` : `School`,
        to: toSchool ? `School` : `${areaName}`,
      };
    }
  }

  /**
   * Parsing error using route service and then show the error toast.
   * An arrow function used here to explicitly hard bind "this" scope to the BusSystemService.
   * @param error
   * @private
   */
  private _errorHandler = (error): Observable<never> => {
    this.routeService.checkErr(error).then((err: any) => {
      this.toastService.show('danger', 'Error', err.err);
    });
    return throwError(error);
  };

  /**
   * This function handles 911 error in add excuses calls
   * An arrow function used here to explicitly hard bind "this" scope to the BusSystemService.
   * @param error {any} - response error object
   * @private
   */
  private _excuseErrorHandler = (error) => {
    if (error.error.errors) {
      for (const errorInstance of error.error.errors) {
        this.toastService.show('danger', 'Error', errorInstance.message);
      }
    }
  };

  private _parseBuses(res): Bus[] {
    return res.map((bus) => {
      const parsedBus: Bus = {
        collapsed: true,
        busID: bus.busID,
        model: bus.model,
        number: bus.number,
        organizationID: bus.organizationID,
        plateNo: bus.plateNo,
        routes: this._parseRouteInfo(bus.routes),
      };

      return parsedBus;
    });
  }

  private _parseRouteInfo(routes): RouteInfo[] {
    return routes.map((route) => {
      const parsedRoute: RouteInfo = {
        areaName: route.areaName,
        code: route.code,
        direction: route.direction,
        distance: route.distance,
        routeID: route.routeID,
        startTime: this.dateUtils
          .formatStringDateInOrganizationTimezone(route.startTime, 'HH:mm:ss')
          .format('hh:mm A'),
      };

      return parsedRoute;
    });
  }

  private parseChildNotes(childNoteResult: any): ChildNote[] {
    return childNoteResult.map((childNote) => ({
      childID: childNote.childID,
      addedOn: childNote.addedOn,
      body: childNote.body,
      date: childNote.date,
      childNoteID: childNote.childNoteID,
      deletedOn: moment(childNote.deletedOn),
      routeID: childNote.routeID,
    }));
  }
  /**
   * This getter return boolean the indicates if the current user can perform POST actions in bus-system
   */
  get canAlterBusSystem(): boolean {
    const roleCode = +localStorage.getItem('spareSelectedOrganizationRole');
    if (!Number.isNaN(roleCode)) return !!(roleCode & (1 + 2 + 8192));
    else return false;
  }
}

/**
 * The response object expected from routes
 * * /bus-system/one-time-excuse/admin/
 * * /bus-system/permanent-excuse/admin/
 */
interface ExcusesResponse {
  childName: string;
  externalStudentID: string;
  grade: string;
  className: string;
  date: string;
  description: string;
  originalDirection: 1 | 0; // 0 form school to destination.
  originalStartTime: string;
  originalAreaID: number;
  originalAreaName: string;
  originalBusNumber: string;
  newDirection: 1 | 0; // 0 form school to destination.
  newStartTime: string;
  newAreaID: number;
  newAreaName: string;
  weekday: number;
  permanentExcuseID: number;
  oneTimeExcuseID: number;
}
