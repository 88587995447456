import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchModalService {
  private openModalSubject = new Subject<void>();
  private destroyComponentSubject = new Subject<void>();
  private buildComponentSubject = new Subject<void>();

  openModal$ = this.openModalSubject.asObservable();
  destroyComponent = this.destroyComponentSubject.asObservable();
  buildComponent = this.buildComponentSubject.asObservable();

  constructor() {}

  triggerOpenModal() {
    this.openModalSubject.next();
  }

  triggerDestroyComponent() {
    this.destroyComponentSubject.next();
  }

  triggerBuildComponent() {
    this.buildComponentSubject.next();
  }
}