import * as moment from 'moment';

export class MenuItem {
  itemEntryID: number;
  productID: number;
  lastIncreased: string;
  amountLeft: number;
  externalSKU: string;
  name: string;
  imageURL: string;
  description: string;
  priceInMenu: number;
  originalPrice: number;
  validityInDays: number;
  VAT: number;
  otherTax: number;
  inclusiveVAT: boolean;
  allergenIDs: string;
  sectionID: number;
  servingSize: string;
  sectionName: string;
  iconURL: string;
  hoveredOn: boolean; //To display the remove item from the menu button when hovered on
  status: boolean;
  currencyCode: string;
  currencyPrecision: number;
  allowNotes: boolean;
  notePlaceholder: string;
  deliveryPreference: 'UNSCHEDULED' | 'SCHEDULED' | 'USER_SCHEDULED';
  deliveryDate?: moment.Moment;
  preparationTime: number;
  availableDays: string;
  allowsCashierSetPrice: boolean;
  allowsCashierNote: boolean;
  categoryAssociatedWithVoucher: boolean;
  categoryID: number;
  categoryName: string;
  categoryViewMode: 'list' | 'daily';
  constructor(
    itemEntryID: number,
    productID: number,
    lastIncreased: string,
    amountLeft: number,
    externalSKU: string,
    name: string,
    imageURL: string,
    description: string,
    priceInMenu: number,
    originalPrice: number,
    validityInDays: number,
    VAT: number,
    otherTax: number,
    inclusiveVAT: boolean,
    allergenIDs: string,
    sectionID: number,
    servingSize: string,
    sectionName: string,
    iconURL: string,
    status: boolean,
    currencyCode: string,
    currencyPrecision: number,
    allowNotes: boolean | number,
    deliveryPreference: 'UNSCHEDULED' | 'SCHEDULED' | 'USER_SCHEDULED',
    notePlaceholder: string,
    preparationTime: number,
    deliveryDate?: string,
    availableDays?: string,
    allowsCashierSetPrice?: boolean,
    allowsCashierNote?: boolean,
    categoryAssociatedWithVoucher?: boolean,
    categoryID?: number,
    categoryName?: string,
    categoryViewMode?: 'list' | 'daily',
  ) {
    this.itemEntryID = itemEntryID;
    this.productID = productID;
    this.lastIncreased = lastIncreased;
    this.amountLeft = amountLeft;
    this.externalSKU = externalSKU;
    this.name = name;
    this.imageURL = this.checkURL(imageURL);
    this.description = description;
    this.priceInMenu = priceInMenu;
    this.originalPrice = originalPrice;
    this.validityInDays = validityInDays;
    this.VAT = VAT;
    this.otherTax = otherTax;
    this.inclusiveVAT = inclusiveVAT;
    this.allergenIDs = allergenIDs;
    this.sectionID = sectionID;
    this.servingSize = servingSize;
    this.sectionName = sectionName;
    this.iconURL = this.checkURL(iconURL);
    this.hoveredOn = false;
    this.status = status;
    this.currencyCode = currencyCode;
    this.currencyPrecision = currencyPrecision;
    this.allowNotes = allowNotes === 1;
    this.notePlaceholder = notePlaceholder;
    this.deliveryPreference = deliveryPreference;
    this.deliveryDate = deliveryDate ? moment(deliveryDate) : null;
    this.preparationTime = preparationTime;
    this.availableDays = availableDays;
    this.allowsCashierSetPrice = allowsCashierSetPrice;
    this.allowsCashierNote = allowsCashierNote;
    this.categoryAssociatedWithVoucher = categoryAssociatedWithVoucher;
    this.categoryID = categoryID;
    this.categoryName = categoryName;
    this.categoryViewMode = categoryViewMode;
  }

  checkURL(url: string) {
    if (url === null || url.startsWith('https') || url.startsWith('http')) {
      return url;
    } else {
      return 'https://' + url;
    }
  }
}
